import React from 'react';
import {Link} from 'react-router-dom';
import {
  ButtonSize,
  ButtonVariant, sizeStyles, variantStyles
} from '@common/auth/ui/ito/components/ui-button';

interface LinkProps extends React.ComponentProps<typeof Link> {
  to: string;
  variant?: ButtonVariant;
  size?: ButtonSize;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  children: React.ReactNode;
  className?: string;
}

const UILink: React.FC<LinkProps> = ({
  to,
  variant = ButtonVariant.Primary,
  size = ButtonSize.Medium,
  icon,
  iconPosition = 'left',
  children,
  className = '',
  ...props
}) => {
  const baseStyles =
  'inline-flex items-center gap-x-2 whitespace-nowrap rounded-md border-2 border-transparent text-sm font-semibold text-white transition-all';

  return (
    <Link
      to={to}
      className={`${baseStyles} ${variantStyles[variant]} ${sizeStyles[size]} ${className}`}
      {...props}
    >
      {icon && iconPosition === 'left' && <>{icon}</>}
      {children}
      {icon && iconPosition === 'right' && <>{icon}</>}
    </Link>
  );
};

export default UILink;
