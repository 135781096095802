import {useMutation} from '@tanstack/react-query';
import {apiClient} from '@common/http/query-client';
import {toast} from '@common/ui/toast/toast';
import {onFormQueryError} from '@common/errors/on-form-query-error';
import {useNavigate} from 'react-router-dom';
import {UseFormReturn} from 'react-hook-form';
import {Service} from '@common/ito/composables/useServices';
import routeNames from '@common/ito/route-names';

export interface ServiceFormValues extends Service {
  _method?: string;
}

interface UpdateServiceProps {
  form: UseFormReturn<any>;
  serviceId: number|string|undefined;
}

async function UpdateService(
  payload: ServiceFormValues,
  serviceId: string | number,
): Promise<Response> {
  payload._method = 'PUT';
  const response = await apiClient.post(`services/${serviceId}`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
}

export default function useUpdateService({form, serviceId}: UpdateServiceProps) {
  if (!serviceId) {
    console.error('serviceId is required in useUpdateService!');
    return;
  }

  const navigate = useNavigate();

  return useMutation({
    mutationFn: (payload: ServiceFormValues) => UpdateService(payload, serviceId),
    onSuccess: () => {
      toast('Service updated successfully');
      navigate(routeNames.payments.services.listing);
    },
    onError: (error) => {
      onFormQueryError(error, form);
    },
  })
}
