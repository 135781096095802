import {
  ITO_ServiceForm
} from '@common/auth/ui/ito/payments/services/service-form';
import routeNames from '@common/ito/route-names';

export function ITO_CreateServicePage() {
  const breadcrumbItems = [
    {label: 'Payments', path: routeNames.payments.gateway.listing},
    {label: 'Products & Services', path: routeNames.payments.services.listing},
    {label: 'Add Product', path: ''},
  ];

  return (
    <ITO_ServiceForm
      breadcrumbItems={breadcrumbItems}
    />
  );
}
