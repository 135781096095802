import { useState, useEffect } from 'react';
import { apiClient } from '@common/http/query-client';
import {Currency} from '@common/ito/composables/useCurrencies';
import {MainWebsiteBrand} from '@common/ito/composables/useBrands';

export enum PaymentGatewayMode {
  live = 'live',
  sandbox = 'sandbox',
}

export enum PaymentGatewayStatus {
  active = 'active',
  inactive = 'inactive',
}


export interface Gateway {
  payment_gateway_keys?: GatewayKey[];
  brand?: MainWebsiteBrand;
  payment_method_ids?: number[];
  brand_id?: number;
  reskillify_brand_id?: string;
  reskillify_brand_name?: string;
  currency_id?: number;
  currency?: Currency;
  id: number;
  name: string;
  title: string;
  is_default: number;
  brand_name: string;
  mode: PaymentGatewayMode;
  status: PaymentGatewayStatus;
  client_account_number: string;
  billing_country: string;
  client_id: string;
  admin_email: string;
  created_at?: string;
  updated_at?: string;
}

export interface GatewayKey {
  id: number;
  payment_gateway_id: number;
  type: PaymentGatewayMode;
  public_key: string;
  secret_key: string;
  created_at?: string;
  updated_at?: string;
}

interface GatewayResponse {
  gateways: {
    current_page: number;
    data: Array<Gateway>;
    from: number;
    last_page: number;
    next_page: number | null;
    per_page: number;
    prev_page: number | null;
    to: number;
    total: number;
  };
  status: string;
}

interface UsePaymentGatewayPageResult {
  gateways: Gateway[];
  gateway?: Gateway;
  currentPage: number;
  totalPages: number;
  isPending: boolean;
  isLoading: boolean;
  error: string | null;
  isEnabled: (status: PaymentGatewayStatus) => boolean;
  show: (id?: number|string) => void;
  get: (filter?: object) => void;
  fetchGateways: (page: number, perPage?: number) => void;
  deleteGateway: (id: number) => void;
  setPage: (page: number) => void;
}

export function usePaymentGateway(perPage: number = 10): UsePaymentGatewayPageResult {
  const [isPending, setIsPending] = useState<boolean>(true)
  const [gateways, setGateways] = useState<Gateway[]>([]);
  const [gateway, setGateway] = useState<Gateway>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const get = async (filter?: object) => {
    await fetchGateways(1, 1000, filter)
  }

  const fetchGateways = async (page: number = 1, perPageOverride?: number, filter?: object) => {
    setIsLoading(true);
    setIsPending(true);
    setError(null);
    try {
      const params = new URLSearchParams({
        'per-page': String(perPageOverride ?? perPage),
        page: String(page),
      }).toString();

      const response = await apiClient.get(`payment-gateways?${params}`, {
        params: {filter},
      });
      const data: GatewayResponse = response.data;
      setGateways(data.gateways.data);
      setCurrentPage(data.gateways.current_page);
      setTotalPages(data.gateways.last_page);
    } catch (err) {
      setError('Failed to fetch payment gateways.');
      console.error(err);
    } finally {
      setIsPending(false);
      setIsLoading(false);
    }
  };

  const show = async (id?: number |string) => {
    try {
      if (id === undefined) {
        console.error('ID is required.');
        return
      }

      const response = await apiClient.get(`payment-gateways/${id}`);
      setGateway(response.data['payment-gateway']);
    } catch (err) {
      console.error(err);
    }
  }

  const deleteGateway = async (id: number) => {
    try {
      await apiClient.delete(`payment-gateways/${id}`);
      await fetchGateways(currentPage);
    } catch (err) {
      console.error(err);
    }
  }

  const setPage = (page: number) => {
    setCurrentPage(page);
  };

  const isEnabled = (status: PaymentGatewayStatus): boolean => {
    return status === PaymentGatewayStatus.active;
  };

  // Fetch initial data on mount
  useEffect(() => {
    fetchGateways(currentPage);
  }, [currentPage]);

  return {
    gateways,
    gateway,
    currentPage,
    totalPages,
    isPending,
    isLoading,
    error,
    isEnabled,
    show,
    get,
    fetchGateways,
    deleteGateway,
    setPage,
  };
}
