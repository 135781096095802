import {Trans} from '@common/i18n/trans';
import React, {ReactNode} from 'react';

interface TableProps {
  className?: string;
  children: ReactNode;
}

export default function Table ({
  children,
  className = '',
}: TableProps) {
  return (
    <main
      className="border-gray-200 max-w-full overflow-x-auto rounded-lg border">
      <table className={`border-gray-200 w-full border bg-white ${className}`}>
        {children}
      </table>
    </main>
  );
}
