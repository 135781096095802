import React, {ReactNode} from 'react';

interface TableTrProps {
  className?: string;
  children: ReactNode;
}

export default function TableTr ({
  children,
  className = '',
}: TableTrProps) {
  return (
    <tr
      className={
        `text-gray-500 border-b hover:cursor-pointer hover:text-[#590494] ${className}`
      }
    >
      {children}
    </tr>
  );
}
