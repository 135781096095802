import React from 'react';
import Breadcrumb from '@common/auth/ui/ito/breadcrumb';
import {Trans} from '@common/i18n/trans';
// import {PencilWithLineIcon} from '@common/auth/ui/ito/icons/pencil-with-line-icon';
// import {WrenchIcon} from '@common/auth/ui/ito/icons/wrench-icon';
import Switcher from '@common/auth/ui/ito/components/switcher';
import Tooltip, {TooltipPosition} from '@common/auth/ui/ito/components/tooltip';
import {IHelpIcon} from '@common/auth/ui/ito/icons/i-help-icon';
import AngleIcon from '@common/ui/layout/ito/sidebar/angle-icon';
import UIButton, {
  ButtonSize,
  ButtonVariant,
} from '@common/auth/ui/ito/components/ui-button';
import UILink from '@common/auth/ui/ito/components/ui-link';
import {Form} from '@common/ui/forms/form';
import {CurrencySymbolPosition} from '@common/ito/composables/useCurrencies';
import InputField from '@common/auth/ui/ito/components/input-field';
import {SelectField} from '@common/auth/ui/ito/components/select-field';
import {useParams} from 'react-router-dom';
import usePaymentGatewayForm
  from '@common/auth/ui/ito/payments/gateway/use-payment-gateway-form';
import {
  PaymentGatewayFormValues
} from '@common/auth/ui/ito/payments/gateway/edit/update-payment-gateway';
import {ProgressCircle} from '@common/ui/progress/progress-circle';
import {StaticPageTitle} from '@common/seo/static-page-title';

interface PaymentGatewayFormProps {
  breadcrumbItems: {label: string, path: string}[];
}

export function ITO_PaymentGatewayForm({
  breadcrumbItems,
}: PaymentGatewayFormProps) {
  const {gatewayId: paymentGatewayId} = useParams();
  const {gateway: paymentGatewayName} = useParams();
  const {
    user,
    isLoading,
    gateways,
    paymentMethods,
    brands,
    currencies,
    form,
    onSubmit,
    onModeChanged,
    currentCurrency,
  } = usePaymentGatewayForm({
    paymentGatewayId,
    paymentGatewayName,
  });

  function onFormSubmit (data: PaymentGatewayFormValues) {
    if (!paymentGatewayId) {
      return onSubmit(data)
    }

    return onSubmit({...data, id: paymentGatewayId})
  }

  return (
    <div>
      <StaticPageTitle>
        <Trans
          message={`${paymentGatewayId ? 'Edit' : 'Add'} Payment Gateway`}
        />
      </StaticPageTitle>

      <Breadcrumb items={breadcrumbItems} className="mb-5" />

      <header className="sm:mt-7 mb-6 flex flex-col items-start justify-between space-y-3 lg:flex-row lg:space-y-0">
        <div className="w-1/2">
          <h2 className="mb-3 text-xl font-semibold text-black">
            <Trans
              message={
                (paymentGatewayId ? 'Edit' : 'Add') + ' Current Payment Gateway'
              }
            />
          </h2>
          <p className="text-gray-500 text-sm">
            <Trans message="See Gateway activities below" />
          </p>
        </div>
        <div className="flex gap-x-2">
          {/*{paymentGatewayId && (*/}
          {/*  <UILink to="" icon={<PencilWithLineIcon />}>*/}
          {/*    <Trans message="Edit" />*/}
          {/*  </UILink>*/}
          {/*)}*/}
          <UIButton
            variant={
              !!form.getValues('status')
                ? ButtonVariant.Success
                : ButtonVariant.Danger
            }
            icon={
              !!form.getValues('status') ? (
                <div className="bg-green-500 group-hover:bg-red-500 h-2 w-2 rounded-full"></div>
              ) : (
                <div className="bg-red-500 group-hover:bg-red-500 h-2 w-2 rounded-full"></div>
              )
            }
            onClick={() => {
              form.setValue('status', !form.getValues('status'));
            }}
          >
            {!!form.getValues('status') ? (
              <Trans message="Active" />
            ) : (
              <Trans message="Inactive" />
            )}
          </UIButton>
          {/*<UIButton variant={ButtonVariant.Secondary} icon={<WrenchIcon />}>*/}
          {/*  <Trans message="Sandbox" />*/}
          {/*</UIButton>*/}
        </div>
      </header>
      <main>
        {isLoading ? (
          <ProgressCircle
            className="my-80"
            size="sm"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              margin: '0',
            }}
            aria-label="Loading payment gateways..."
            isIndeterminate
          />
        ) : (
          <Form
            form={form}
            id="gateway-form"
            className="space-y-8"
            onSubmit={onFormSubmit}
          >
            <section className="space-y-6 rounded-lg bg-white px-5 py-6 shadow-sm">
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                <InputField
                  label="Display Name"
                  form={form}
                  name="title"
                  inputTooltipMessage="Gateway title is required"
                  inputTooltipPosition={TooltipPosition.Top}
                  required={true}
                />
                <SelectField
                  label="Default Gateway"
                  id="default_payment_gateway_id"
                  name="default_payment_gateway_id"
                  form={form}
                  options={gateways.map(gateway => ({
                    value: gateway.id,
                    label: `${gateway.name} (${gateway.title})`,
                  }))}
                />
              </div>
              <div className="flex items-center justify-between">
                <label className="text-gray-800 block text-sm font-semibold">
                  <Trans message="Select a Gateway Mode" />
                </label>
                <Switcher
                  checked={form.getValues('mode') === 'sandbox'}
                  onChange={onModeChanged}
                  Before={
                    <span className="text-gray-800 inline-flex items-center text-sm">
                      Live
                      <Tooltip
                        position={TooltipPosition.Left}
                        message="Live"
                        Trigger={
                          <IHelpIcon className="text-gray-300 hover:text-gray-400 ml-1 h-4 w-4" />
                        }
                      />
                    </span>
                  }
                  After={
                    <span className="text-gray-800 inline-flex items-center text-sm">
                      Sandbox
                      <Tooltip
                        position={TooltipPosition.Left}
                        message="Sandbox"
                        Trigger={
                          <IHelpIcon className="text-gray-300 hover:text-gray-400 ml-1 h-4 w-4" />
                        }
                      />
                    </span>
                  }
                />
              </div>
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                <InputField
                  id="public-key"
                  label="Public Key"
                  placeholder="*************"
                  type="password"
                  required={true}
                  form={form}
                  name="public_key"
                  autoComplete="new-password"
                />
                <InputField
                  id="secret-key"
                  label="Secret Key"
                  placeholder="*************"
                  type="password"
                  required={true}
                  form={form}
                  name="secret_key"
                  autoComplete="new-password"
                />
              </div>
              {/*<div className="mt-4 flex justify-end gap-4">*/}
              {/*<UIButton type="submit" variant={ButtonVariant.Secondary}>*/}
              {/*  <Trans message="Test Connection" />*/}
              {/*</UIButton>*/}
              {/*<UIButton>*/}
              {/*  <Trans message="Save" />*/}
              {/*</UIButton>*/}
              {/*</div>*/}
            </section>
            <section className="space-y-6 rounded-lg bg-white px-5 py-6 shadow-sm">
              <div className="grid grid-cols-1 items-end gap-6 md:grid-cols-2">
                <InputField
                  id="client-id"
                  label="Client ID"
                  placeholder="8A9d94Gdh"
                  form={form}
                  name="client_id"
                />
                <InputField
                  id="admin-email"
                  label="Admin Email Address"
                  placeholder={user?.email ?? 'admin@reskillify.co'}
                  labelTooltipMessage="Admin Email Address"
                  labelTooltipPosition={TooltipPosition.Left}
                  form={form}
                  name="admin_email"
                  disabled={true}
                />
              </div>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <SelectField
                  id="payment-method"
                  name="payment_method_ids"
                  label="Payment Method"
                  options={paymentMethods.map(method => ({
                    value: method.id,
                    label: method.name,
                  }))}
                  required={true}
                  multiple={true}
                  form={form}
                />
                <div>
                  <SelectField
                    id="payment-brand-id"
                    name="reskillify_brand_id"
                    label="Payment Brand"
                    options={brands.map(brand => ({
                      value: brand.id,
                      label: brand.name as string,
                    }))}
                    form={form}
                  />
                  <span className="text-gray-400 text-xs">
                    <Trans message="Usage: The Payment gateway is used by reskillify brand" />
                  </span>
                </div>
              </div>
            </section>

            <section className="flex flex-col space-y-6 lg:flex-row lg:space-x-6 lg:space-y-0">
              <section className="w-full rounded-lg bg-white px-5 py-6 shadow-sm lg:w-3/4 lg:space-y-6">
                <h2 className="mb-4 font-semibold">
                  <Trans message="Billing Settings" />
                </h2>

                <div className="grid grid-cols-1 gap-x-4 gap-y-5 md:grid-cols-2">
                  <div className="col-span-1 md:col-span-2">
                    <SelectField
                      label="Default Country"
                      form={form}
                      id="billing_country"
                      name="billing_country"
                      required={true}
                      options={['USA', 'Canada'].map(brand => ({
                        value: brand,
                        label: brand,
                      }))}
                    />
                  </div>
                  <div>
                    <SelectField
                      id="currency_id"
                      name="currency_id"
                      label="Currency"
                      options={currencies.map(currency => ({
                        value: currency.id,
                        label: `${currency.code} (${currency.symbol})`,
                      }))}
                      form={form}
                    />
                  </div>
                  <div>
                    <SelectField
                      id="currency-symbol-position"
                      name="currency_symbol_position"
                      label="Currency Symbol Position"
                      options={[
                        {value: '', label: 'Select Currency Position'},
                        {value: CurrencySymbolPosition.left, label: 'Left'},
                        {value: CurrencySymbolPosition.right, label: 'Right'},
                      ]}
                      disabled={
                        currentCurrency()?.symbol_position !== undefined &&
                        [
                          CurrencySymbolPosition.left,
                          CurrencySymbolPosition.right,
                        ].indexOf(
                          currentCurrency()
                            ?.symbol_position as CurrencySymbolPosition,
                        ) !== -1
                      }
                      required={true}
                      form={form}
                    />
                  </div>
                </div>
              </section>
              {/*<section className="w-full space-y-3 rounded-lg bg-white px-5 py-6 shadow-sm lg:w-1/3 lg:space-y-6">*/}
              {/*  <h2 className="font-semibold">*/}
              {/*    <Trans message="Webhooks" />*/}
              {/*  </h2>*/}

              {/*  <p className="text-gray-500 text-sm">*/}
              {/*    <strong>*/}
              {/*      <Trans message="Live webhook is not set up" />*/}
              {/*    </strong>*/}
              {/*    <br />*/}
              {/*    <Trans message="Never received event" />*/}
              {/*  </p>*/}
              {/*  <p className="text-gray-500 text-sm">*/}
              {/*    <strong>*/}
              {/*      <Trans message="Test webhook may not work properly" />*/}
              {/*    </strong>*/}
              {/*    <br />*/}
              {/*    <Trans message="Last event 20 days ago" />*/}
              {/*  </p>*/}

              {/*  <UIButton*/}
              {/*    icon={<AngleIcon down={false} className="h-4 w-4" />}*/}
              {/*    iconPosition="right"*/}
              {/*    className="text-purple-800 gap-x-2"*/}
              {/*    variant={ButtonVariant.Transparent}*/}
              {/*    size={ButtonSize.None}*/}
              {/*    type='button'*/}
              {/*  >*/}
              {/*    <Trans message="Show Webhook Info" />*/}
              {/*  </UIButton>*/}
              {/*</section>*/}
            </section>

            <section className="flex justify-end gap-x-2">
              <UILink to="/payments/gateway" variant={ButtonVariant.Secondary}>
                Cancel
              </UILink>
              {/*<UIButton variant={ButtonVariant.Secondary}>*/}
              {/*  <Trans message="Cancel" />*/}
              {/*</UIButton>*/}
              <UIButton>
                <Trans message="Save Settings" />
              </UIButton>
            </section>
          </Form>
        )}
      </main>
    </div>
  );
}
