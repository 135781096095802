import Badge, {BadgeType} from '@common/auth/ui/ito/components/badge';

interface StatusBadgeProps {
  status: boolean;
  activeStatusText?: string;
  inactiveStatusText?: string;
}

export default function StatusBadge({
  status,
  activeStatusText = 'Active',
  inactiveStatusText = 'Inactive',
}: StatusBadgeProps) {
  return (
    <Badge
      type={status ? BadgeType.Success : BadgeType.Danger}
    >
      {status ? activeStatusText : inactiveStatusText}
    </Badge>
  );
}
