/**
 * InputField Component
 *
 * A reusable input field component with support for labels, tooltips, and error handling,
 * integrated with `react-hook-form` for form state management.
 *
 * Props:
 * --------
 * @param {string} id - The unique identifier for the input field. Optional.
 * @param {string} label - The label text displayed above the input field. Required.
 * @param {string} [placeholder] - Placeholder text for the input field. Optional.
 * @param {string} [type='text'] - The input field type (e.g., text, password, email). Defaults to 'text'.
 * @param {boolean} [required=false] - Indicates whether the input field is required. Defaults to false.
 * @param {string} [labelTooltipMessage] - A tooltip message displayed next to the label. Optional.
 * @param {TooltipPosition} [labelTooltipPosition=TooltipPosition.Top] - The position of the label's tooltip. Defaults to 'Top'.
 * @param {string} [labelTooltipColor='bg-gray-600'] - The background color of the label's tooltip. Defaults to 'bg-gray-600'.
 * @param {string} [inputTooltipMessage] - A tooltip message displayed inside the input field area. Optional.
 * @param {TooltipPosition} [inputTooltipPosition=TooltipPosition.Top] - The position of the input field's tooltip. Defaults to 'Top'.
 * @param {string} [inputTooltipColor='bg-gray-600'] - The background color of the input field's tooltip. Defaults to 'bg-gray-600'.
 * @param {UseFormReturn<any>} form - The `react-hook-form` instance used to manage form state. Required.
 * @param {string} name - The name of the input field, used for form registration and error handling. Required.
 * @param {string} [autoComplete] - Determines the autocomplete behavior for the input field. Optional.
 *
 * Example Usage:
 * --------------
 * <InputField
 *   id="username"
 *   label="Username"
 *   placeholder="Enter your username"
 *   required={true}
 *   labelTooltipMessage="Your unique username"
 *   labelTooltipPosition={TooltipPosition.Bottom}
 *   labelTooltipColor="bg-blue-500"
 *   inputTooltipMessage="Username must be alphanumeric"
 *   inputTooltipPosition={TooltipPosition.Right}
 *   inputTooltipColor="bg-green-500"
 *   form={form}
 *   name="username"
 *   autoComplete="off"
 * />
 *
 * Features:
 * ---------
 * - Displays a label with an optional tooltip.
 * - Integrates with `react-hook-form` for validation and state management.
 * - Shows error messages below the input field if validation fails.
 * - Supports tooltips within the input field for additional guidance.
 * - Highly customizable with props for labels, tooltips, and styles.
 *
 * Error Handling:
 * ---------------
 * The `error` prop is automatically derived from the `react-hook-form` instance.
 * If the field validation fails, the error message will be displayed below the input.
 */

import React, {ReactNode} from 'react';
import {UseFormReturn} from 'react-hook-form';
import Tooltip, {TooltipPosition} from '@common/auth/ui/ito/components/tooltip';
import {IHelpIcon} from '@common/auth/ui/ito/icons/i-help-icon';
import FieldLabel from '@common/auth/ui/ito/components/field-label';

export interface InputFieldProps {
  id?: string;
  label?: string | ReactNode;
  placeholder?: string;
  type?: string;
  required?: boolean;
  disabled?: boolean;
  labelTooltipMessage?: string;
  labelTooltipPosition?: TooltipPosition;
  labelTooltipColor?: string;

  inputTooltipMessage?: string;
  inputTooltipPosition?: TooltipPosition;
  inputTooltipColor?: string;

  form: UseFormReturn<any>;
  name: string;
  autoComplete?: string;

  className?: string;
}

const InputField: React.FC<InputFieldProps> = ({
  id,
  label = '',
  placeholder,
  type = 'text',
  required = false,
  disabled = false,
  labelTooltipMessage = undefined,
  labelTooltipPosition = TooltipPosition.Top,
  labelTooltipColor = 'bg-gray-600',
  inputTooltipMessage = '',
  inputTooltipPosition = TooltipPosition.Top,
  inputTooltipColor = 'bg-gray-600',
  form,
  name,
  autoComplete,
  className = '',
}) => {
  const error = form.formState.errors[name];
  const errorMessage = error && 'message' in error ? error.message : '';

  const disabledClasses = disabled ? 'text-gray-400' : '';
  // text-gray-400 focus:ring-purple-500 focus:border-purple-500 w-full react-select--is-disabled css-3iigni-container

  return (
    <div className={'mb-4 ' + className}>
      {label && (
        <FieldLabel
          label={label}
          for={id}
          starBefore={required}
          tooltipMessage={labelTooltipMessage}
          tooltipPosition={labelTooltipPosition}
          tooltipColor={labelTooltipColor}
        />
      )}
      <div className="relative w-full">
        <input
          {...form.register(
            name,
            required ? {required: `${label} is required`} : {},
          )}
          id={id}
          placeholder={placeholder}
          type={type}
          className={
            'border-gray-300 focus:ring-purple-500 focus:border-purple-500 px-2.5 py-1.5 w-full rounded-md border shadow-sm ' + disabledClasses
          }
          autoComplete={autoComplete}
          disabled={disabled}
        />

        {inputTooltipMessage && inputTooltipPosition && (
          <div className="text-gray-500 hover:text-gray-700 absolute inset-y-0 right-1 flex items-center focus:outline-none">
            <Tooltip
              message={inputTooltipMessage}
              position={inputTooltipPosition}
              color={inputTooltipColor}
              className="w-max"
              Trigger={
                <IHelpIcon className="text-gray-300 hover:text-gray-400 mr-2 h-4 w-4" />
              }
            />
          </div>
        )}
      </div>

      {errorMessage && (
        <div className="text-red-500 text-xs">{errorMessage as string}</div>
      )}
    </div>
  );
};

export default InputField;
