import React, {useEffect, useRef, useState} from 'react';

export enum TooltipPosition {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right'
}

interface TooltipProps {
  Trigger: React.ReactNode;
  message: string;
  color?: string;
  className?: string;
  position?: TooltipPosition;
}

const Tooltip = ({ message, Trigger, color = 'bg-black', className = '', position = TooltipPosition.Top }: TooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [adjustedPosition, setAdjustedPosition] = useState<TooltipPosition>(position);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (isVisible && tooltipRef.current && triggerRef.current) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Adjust position if tooltip overflows the viewport
      if (position === TooltipPosition.Top && triggerRect.top - tooltipRect.height < 0) {
        setAdjustedPosition(TooltipPosition.Bottom);
      } else if (position === TooltipPosition.Bottom && triggerRect.bottom + tooltipRect.height > viewportHeight) {
        setAdjustedPosition(TooltipPosition.Top);
      } else if (position === TooltipPosition.Left && triggerRect.left - tooltipRect.width < 0) {
        setAdjustedPosition(TooltipPosition.Right);
      } else if (position === TooltipPosition.Right && triggerRect.right + tooltipRect.width > viewportWidth) {
        setAdjustedPosition(TooltipPosition.Left);
      } else {
        setAdjustedPosition(position); // Retain the original position if no adjustment is needed
      }
    }
  }, [isVisible, position]);

  // Utility function to get the positioning classes
  const getPositionClasses = () => {
    switch (position) {
      case TooltipPosition.Top:
        return 'bottom-full mb-2 left-1/2 transform -translate-x-1/2';
      case TooltipPosition.Bottom:
        return 'top-full mt-2 left-1/2 transform -translate-x-1/2';
      case TooltipPosition.Left:
        return 'right-full mr-2 top-1/2 transform -translate-y-1/2';
      case TooltipPosition.Right:
        return 'left-full ml-2 top-1/2 transform -translate-y-1/2';
      default:
        return 'bottom-full mb-2 left-1/2 transform -translate-x-1/2';
    }
  };

  // Utility function to get the arrow positioning classes
  const getArrowPositionClasses = () => {
    switch (position) {
      case TooltipPosition.Top:
        return 'bottom-0 -mb-1 left-1/2 transform -translate-x-1/2 rotate-45';
      case TooltipPosition.Bottom:
        return 'top-0 -mt-1 left-1/2 transform -translate-x-1/2 rotate-45';
      case TooltipPosition.Left:
        return 'right-0 -mr-1 top-1/2 transform -translate-y-1/2 rotate-45';
      case TooltipPosition.Right:
        return 'left-0 -ml-1 top-1/2 transform -translate-y-1/2 rotate-45';
      default:
        return 'bottom-0 -mb-1 left-1/2 transform -translate-x-1/2 rotate-45';
    }
  };

  return (
    <div className="relative inline-flex justify-center items-center">
      <span
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className='h-fit'
      >
        {Trigger}
      </span>
      {isVisible && (
        <div
          ref={tooltipRef}
          className={`absolute ${getPositionClasses()} rounded px-3 py-2 text-sm text-white shadow-lg ${color} ${className}`}
        >
          {message}
          <div
            className={`absolute h-3 w-3 ${color} ${getArrowPositionClasses()}`}
          ></div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
