import React, { useState, useEffect } from 'react';
import { apiClient } from '@common/http/query-client';
import {MainWebsiteBrand} from '@common/ito/composables/useBrands';
import {Currency} from '@common/ito/composables/useCurrencies';

export interface Service {
  id?: number;
  brand_id?: number | string;
  currency_id?: number | string;
  name: string;
  service_type: string;
  image?: string | any;
  descriptions?: string;
  statement_descriptions: string;
  product_id_on_reskillify: string;
  pricing_model?: string;
  recurring: number | string;
  duration: string;
  quantity: number | string;
  sku: string;
  amount: string;
  sale_price: string | undefined;
  purchase_note: string;
  upsell: number | boolean;
  bump: number | boolean;
  one_time_order: number | boolean;
  affiliate_commission: number | boolean;
  type: string;
  status: boolean | number;
  brand?: MainWebsiteBrand;
  sale?: Sale;
  currency?: Currency;
  created_at: string;
  updated_at: string;
}
export interface Sale {
  id: 4,
  service_id: number,
  quantity: number,
  sale_price: string,
  created_at: string,
  updated_at: string,
}

interface ServiceResponse {
  data: Array<Service>;
  links: {
    first: string | null;
    last: string | null;
    prev: string | null;
    next: string | null;
  },
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    next_page: number | null;
    per_page: number;
    prev_page: number | null;
    to: number;
    total: number;
  }
  status?: string;
}

interface UseServicePageResult {
  services: Service[];
  service?: Service;
  currentPage: number;
  totalPages: number;
  isLoading: boolean;
  error: string | null;
  deleteDialogOpened: boolean;
  show: (id?: number|string) => void;
  get: (filter?: object) => void;
  fetch: (page: number, perPage?: number) => void;
  remove: (id: number) => void;
  setPage: (page: number) => void;
  setService: (service: Service) => void;
  openDeleteDialog: (id: number) => void;
  handleDeleteDialogClose: () => void;
  handleDeleteDialogConfirm: () => void;
}

export function useServices(perPage: number = 10): UseServicePageResult {
  const [services, setServices] = useState<Service[]>([]);
  const [service, setService] = useState<Service>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [deleteDialogOpened, setDeleteDialogOpened] = React.useState(false);
  const [selectedServiceId, setSelectedServiceId] = React.useState<number | null>(null);

  const get = async (filter?: object) => {
    await fetch(1, 1000, filter)
  }

  const fetch = async (page: number = 1, perPageOverride?: number, filter?: object) => {
    setIsLoading(true);
    setError(null);
    try {
      const params = new URLSearchParams({
        'per-page': String(perPageOverride ?? perPage),
        page: String(page),
      }).toString();

      const response = await apiClient.get(`services?${params}`, {
        params: {filter},
      });
      const data: ServiceResponse = response.data;
      setServices(data.data);
      setCurrentPage(data.meta.current_page);
      setTotalPages(data.meta.last_page);
    } catch (err) {
      setError('Failed to fetch services.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const show = async (id?: number |string) => {
    try {
      if (id === undefined) {
        console.error('ID is required.');
        return
      }

      const response = await apiClient.get(`services/${id}`);
      setService(response.data['data']);
    } catch (err) {
      console.error(err);
    }
  }

  const remove = async (id: number) => {
    try {
      await apiClient.delete(`services/${id}`);
      await fetch(currentPage);
    } catch (err) {
      console.error(err);
    }
  }

  const setPage = (page: number) => {
    setCurrentPage(page);
  };

  function openDeleteDialog(paymentGatewayId: number) {
    setDeleteDialogOpened(true);
    setSelectedServiceId(paymentGatewayId)
  }

  function handleDeleteDialogClose() {
    setDeleteDialogOpened(false);
    setSelectedServiceId(null)
  }

  function handleDeleteDialogConfirm() {
    if (selectedServiceId) {
      remove(selectedServiceId);
    }

    handleDeleteDialogClose()
  }

  // Fetch initial data on mount
  useEffect(() => {
    fetch(currentPage);
  }, [currentPage]);

  return {
    services,
    service,
    currentPage,
    totalPages,
    isLoading,
    error,
    deleteDialogOpened,
    setService,
    get,
    show,
    fetch,
    remove,
    setPage,
    openDeleteDialog,
    handleDeleteDialogClose,
    handleDeleteDialogConfirm,
  };
}
