import React, {useState} from 'react';
import {Trans} from '@common/i18n/trans';

interface GatewayProvider {
  id: number;
  name: string;
}

export interface ChooseProviderSelectBoxProps {
  onProviderChange: (provider: string) => void;
}

export function ChooseProviderSelectBox({ onProviderChange }: ChooseProviderSelectBoxProps) {
    const [selectedProvider, setSelectedProvider] = useState<string>('');
  const [gatewayProviders, setGatewayProviders] = useState<GatewayProvider[]>([
    {
      id: 1,
      name: 'Stripe',
    },
    // {
    //   id: 2,
    //   name: 'PayPal',
    // },
    // {
    //   id: 3,
    //   name: 'Authorize.net',
    // },
    // {
    //   id: 4,
    //   name: 'GoCardLess',
    // },
    // {
    //   id: 5,
    //   name: 'RazorPay',
    // },
    // {
    //   id: 6,
    //   name: 'FlutterWave',
    // },
    // {
    //   id: 7,
    //   name: 'RazorPay',
    // },
    // {
    //   id: 8,
    //   name: 'MercadoPago',
    // },
    // {
    //   id: 9,
    //   name: 'Instamojo',
    // },
    // {
    //   id: 10,
    //   name: 'SecurePay',
    // },
    // {
    //   id: 11,
    //   name: 'AmazonPay',
    // },
  ])

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value
    setSelectedProvider(selectedValue);
    onProviderChange(selectedValue)
  };

  return (
    <div>
      <label htmlFor="provider" className="text-sm">
        Provider
      </label>
      <select
        onChange={handleChange}
        id="provider"
        className="border-gray-300 text-gray-500 focus:ring-purple-500 focus:border-purple-500 mt-1 block w-full rounded-lg border p-3 text-sm shadow-sm"
      >
        <option value=''>
          <Trans message="Select Provider" />
        </option>
        {gatewayProviders.map((provider: GatewayProvider) => (
          <option key={provider.id} value={provider.name}>
            {provider.name}
          </option>
        ))}
      </select>
    </div>
  );
}
