import {Trans} from '@common/i18n/trans';
import {
  PaymentMethodRow
} from '@common/auth/ui/ito/payments/gateway/choose-payment-gateway/payment-method-row';
import React, {useEffect, useState} from 'react';
import {
  PaymentGatewayBasicItems
} from '@common/auth/ui/ito/payments/gateway/choose-payment-gateway/index';

interface PaymentMethodsSectionProps {
  selectedProvider: string;
}

export function PaymentMethodsSection({selectedProvider}: PaymentMethodsSectionProps) {
  const allPaymentGateways: PaymentGatewayBasicItems[] = [
    // {id: 1, name: 'Authorize.net', imgSrc: '/images/icons/authorize-dot-net.svg'},
    {id: 2, name: 'Stripe', imgSrc: '/images/icons/stripe.svg'},
    // {id: 3, name: 'PayPal', imgSrc: '/images/icons/paypal.png'},
    // {id: 4, name: 'GoCardLess', imgSrc: '/images/icons/go-card-less.png'},
    // {id: 5, name: 'RazorPay', imgSrc: '/images/icons/razorpay.png'},
    // {id: 6, name: 'FlutterWave', imgSrc: '/images/icons/flutter-wave.png'},
    // {id: 7, name: 'RazorPay', imgSrc: '/images/icons/razorpay.png'},
    // {id: 8, name: 'MercadoPago', imgSrc: '/images/icons/mercado-pago.png'},
    // {id: 9, name: 'Instamojo', imgSrc: '/images/icons/instamojo.png'},
    // {id: 10, name: 'SecurePay', imgSrc: '/images/icons/secure-pay.png'},
    // {id: 11, name: 'AmazonPay', imgSrc: '/images/icons/amazon-pay.png'},
    // {id: 12, name: 'Maverick', imgSrc: '/images/icons/maverick.png'},
    // {id: 13, name: 'Adyen', imgSrc: '/images/icons/adyen.png'},
  ];

  const [paymentGateways, setPaymentGateways] = useState<PaymentGatewayBasicItems[]>(allPaymentGateways);

  useEffect(() => {
    let filteredPaymentGateway = allPaymentGateways?.find((paymentGateway: PaymentGatewayBasicItems) => {
      return paymentGateway.name === selectedProvider;
    })

    if (!filteredPaymentGateway) {
      setPaymentGateways(allPaymentGateways);

      return
    }

    setPaymentGateways([filteredPaymentGateway]);

  }, [selectedProvider]);

  return (
    <section className="mb-6 px-5 py-4 rounded-lg bg-white shadow-sm">
      <header>
        <h2 className="border-gray-100 text-md border-b pb-1 font-semibold">
          <Trans message='Payment Method' />
        </h2>
      </header>
      <main>
        {paymentGateways.map((gateway: PaymentGatewayBasicItems) => (
          <PaymentMethodRow
            key={gateway.id}
            gateway={gateway}
          />
        ))}
      </main>
    </section>
  );
}
