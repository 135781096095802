import React, {useState} from 'react';
import {
  RecommendationSection
} from '@common/auth/ui/ito/payments/gateway/choose-payment-gateway/recommendation-section';
import {
  PaymentMethodsSection
} from '@common/auth/ui/ito/payments/gateway/choose-payment-gateway/payment-methods-section';
import {
  Header
} from '@common/auth/ui/ito/payments/gateway/choose-payment-gateway/header';
import {
  AddPaymentGatewaySection
} from '@common/auth/ui/ito/payments/gateway/choose-payment-gateway/add-payment-gateway-section';
import {StaticPageTitle} from '@common/seo/static-page-title';
import {Trans} from '@common/i18n/trans';

export interface PaymentGatewayBasicItems {
  id: number;
  name: string;
  imgSrc: string;
}

export function ITO_ChoosePaymentGatewayPage() {
  const [selectedProvider, setSelectedProvider] = useState<string>('');

  const handleProviderChange = (provider: string) => {
    setSelectedProvider(provider);
  };

  return (
    <div className="">
      <StaticPageTitle>
        <Trans message="Choose The Payment Gateway You Want To Use To Get Paid" />
      </StaticPageTitle>
      <Header />
      <AddPaymentGatewaySection onProviderChange={handleProviderChange} />
      <PaymentMethodsSection selectedProvider={selectedProvider} />
      <hr className="border-gray-300 mb-6" />
      {/*<RecommendationSection />*/}
    </div>
  );
}
