import {Trans} from '@common/i18n/trans';
import {UpDownArrowsIcon} from '@common/auth/ui/ito/icons/up-down-arrows-icon';
import {ProgressCircle} from '@common/ui/progress/progress-circle';
// import {StarIcon} from '@common/auth/ui/ito/icons/star-icon';
// import {
//   ExclamationMarkInCircleIcon
// } from '@common/auth/ui/ito/icons/exclamation-mark-in-circle-icon';
// import {
//   CrossInCircleIcon
// } from '@common/auth/ui/ito/icons/cross-in-circle-icon';
// import {ChatIcon} from '@common/auth/ui/ito/icons/chat-icon';
import {PencilIcon} from '@common/auth/ui/ito/icons/pencil-icon';
import {TrashIcon} from '@common/auth/ui/ito/icons/trash-icon';
import {
  Gateway,
  PaymentGatewayStatus,
} from '@common/ito/composables/usePaymentGateway';
import {Link} from 'react-router-dom';
import StatusBadge from '@common/auth/ui/ito/components/status-badge';
import routeNames from '@common/ito/route-names';
import TableH from '@common/auth/ui/ito/components/table/TableH';
import TableD from '@common/auth/ui/ito/components/table/TableD';
import Table from '@common/auth/ui/ito/components/table/Table';
import TableHead from '@common/auth/ui/ito/components/table/TableHead';
import TableBody from '@common/auth/ui/ito/components/table/TableBody';
import TableTr from '@common/auth/ui/ito/components/table/TableTr';
import React from 'react';
import {TailwindDialog} from '@common/auth/ui/ito/components/TailwindDialog';
import usePaymentGatewayTable
  from '@common/auth/ui/ito/payments/gateway/listing/use-payment-gateway-table';

interface PaymentGatewaysTableProps {
  gateways: Gateway[];
  isLoading: boolean;
  error: string | null;
  isEnabled: (status: PaymentGatewayStatus) => boolean;
  deleteGateway: (id: number) => void;
  onSort: (field: string) => void;
}

export function PaymentGatewayTable({ gateways, isLoading, error, isEnabled, deleteGateway, onSort }: PaymentGatewaysTableProps) {
  const {
    deleteDialogOpened,
    // handleSortClick,
    openDeleteDialog,
    handleDeleteDialogClose,
    handleDeleteDialogConfirm,
  } = usePaymentGatewayTable({
    onSort,
    onDelete: deleteGateway,
  })

  return (
    <>
      <TailwindDialog
        isOpen={deleteDialogOpened}
        title='Confrim Payment Gateway Deletion'
        message='Are you sure? You want to delete?'
        confirmText='Yes, Delete it'
        onConfirm={handleDeleteDialogConfirm}
        onClose={handleDeleteDialogClose}
      />
      <Table>
        <TableHead>
          <TableTr>
            <TableH>
              <div className="flex items-center justify-between">
                <div className="mb-[0.125rem] flex min-h-[1.5rem] items-center ps-[1.5rem]">
                  <input
                    className="border-secondary-500 before:shadow-checkbox checked:focus:before:shadow-checkbox dark:border-neutral-400 relative float-left -ms-[1.5rem] me-[6px] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-transparent before:content-[''] checked:border-primary checked:bg-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ms-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-black/60 focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-black/60 focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ms-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent rtl:float-right dark:checked:border-primary dark:checked:bg-primary"
                    type="checkbox"
                    value=""
                    id="checkboxDefault"
                  />
                  <label
                    className="inline-block ps-[0.15rem] hover:cursor-pointer"
                    htmlFor="checkboxDefault"
                  >
                    <Trans message="Gateway Name" />
                  </label>
                </div>
                <UpDownArrowsIcon />
              </div>
            </TableH>
            <TableH>
              <div className="flex items-center justify-between">
                <Trans message="Payment Gateway" />
                <UpDownArrowsIcon />
              </div>
            </TableH>
            <TableH>
              <div className="flex items-center justify-between">
                <Trans message="Brand Name" />
                <UpDownArrowsIcon />
              </div>
            </TableH>
            <TableH>
              <div className="flex items-center justify-between">
                <Trans message="Mode" />
                <UpDownArrowsIcon />
              </div>
            </TableH>
            <TableH>
              <div className="flex items-center justify-between">
                <Trans message="Status" />
                <UpDownArrowsIcon />
              </div>
            </TableH>
            <TableH>
              <div className="flex items-center justify-center">
                <Trans message="Actions" />
              </div>
            </TableH>
          </TableTr>
        </TableHead>
        <TableBody>
          {error && (
            <TableTr>
              <TableD
                colSpan={7}
                text={error || 'Unknown Error'}
                className="relative h-32"
              />
            </TableTr>
          )}
          {isLoading ? (
            <TableTr>
              <TableD colSpan={7} className="relative h-36">
                <ProgressCircle
                  className="my-80"
                  size="sm"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    margin: '0',
                  }}
                  aria-label="Loading payment gateways..."
                  isIndeterminate
                />
              </TableD>
            </TableTr>
          ) : (
            gateways.map(gateway => (
              <TableTr key={gateway.id}>
                <TableD>
                  <div className="flex items-center justify-between">
                    <span>{gateway.name}</span>

                    {Boolean(gateway.is_default) && (
                      <span className="ml-2 rounded-md border bg-[#EEE6F4] px-2 py-1 text-xs font-semibold text-[#9057B7]">
                        <Trans message="Default" />
                      </span>
                    )}
                  </div>
                </TableD>
                <TableD text={gateway.title || ''} />
                <TableD text={gateway.reskillify_brand_name} />
                <TableD text={gateway.mode} />
                <TableD>
                  <StatusBadge
                    status={gateway.status == 'active'}
                    inactiveStatusText={gateway.status}
                    activeStatusText={gateway.status}
                  />
                </TableD>
                <TableD>
                  <div className="flex justify-center space-x-4">
                  {/*
                    <button
                      className={`h-5 w-5 ${
                        isEnabled(gateway.status)
                          ? 'text-green-500'
                          : 'text-gray-400'
                      }`}
                    >
                      <StarIcon />
                    </button>
                    <button
                      className={`h-5 w-5 ${
                        isEnabled(gateway.status)
                          ? 'text-yellow-500'
                          : 'text-gray-400'
                      }`}
                    >
                      <ExclamationMarkInCircleIcon />
                    </button>
                    <button
                      className={`h-5 w-5 ${
                        isEnabled(gateway.status)
                          ? 'text-gray-500'
                          : 'text-red-500'
                      }`}
                    >
                      <CrossInCircleIcon />
                    </button>
                    <button
                      className={`h-5 w-5 ${
                        isEnabled(gateway.status)
                          ? 'text-yellow-500'
                          : 'text-gray-400'
                      }`}
                    >
                      <ChatIcon />
                    </button>
                  */}
                    <Link
                      to={routeNames.payments.gateway.edit(gateway.id)}
                      className="text-blue-400 hover:text-blue-600 h-5 w-5"
                    >
                      <PencilIcon />
                    </Link>
                    <button
                      className="text-red-500 hover:text-red-700 h-5 w-5"
                      onClick={() => openDeleteDialog(gateway.id)}
                    >
                      <TrashIcon />
                    </button>
                  </div>
                </TableD>
              </TableTr>
            ))
          )}
        </TableBody>
      </Table>
    </>
  );
}
