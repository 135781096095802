import React, {ReactNode, useEffect} from 'react';
import Select from 'react-select';
import { UseFormReturn } from 'react-hook-form';
import FieldLabel from '@common/auth/ui/ito/components/field-label';
import {TooltipPosition} from '@common/auth/ui/ito/components/tooltip';

interface SelectFieldProps {
  id: string;
  name: string;
  label?: string | ReactNode;
  options: { value: number|string; label: string }[];
  required?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  labelTooltipMessage?: string;
  labelTooltipPosition?: TooltipPosition;
  labelTooltipColor?: string;
  form: UseFormReturn<any>;
  className?: string;
  inputClassName?: string;

  readOnly?: boolean;
  onChange?: (data: any) => void;
}

export const SelectField: React.FC<SelectFieldProps> = ({
  id,
  name,
  label = '',
  options,
  required = false,
  multiple = false,
  disabled = false,
  labelTooltipMessage = undefined,
  labelTooltipPosition = TooltipPosition.Top,
  labelTooltipColor = 'bg-gray-600',
  form,
  className = '',
  inputClassName = '',

  readOnly = false,
  onChange = (data: any) => {},
}) => {
  const error = form.formState.errors[name];
  const selectedValue = form.getValues(name);

  // Register the field with validation
  useEffect(() => {
    form.register(name, {
      validate: value =>
        required && (!value || (multiple ? value.length === 0 : value === ''))
          ? `${label} is required`
          : true,
    });
  }, [form, name, label, required, multiple]);

  const handleChange = (selectedOptions: any) => {
    if (multiple) {
      const values = selectedOptions ? selectedOptions.map((opt: any) => opt.value) : [];
      form.setValue(name, values, { shouldValidate: true });
    } else {
      const value = selectedOptions ? selectedOptions.value : '';
      form.setValue(name, value, { shouldValidate: true });
    }

    onChange(selectedOptions);
  };

  return (
    <div className={className}>
      {label && (
        <FieldLabel
          label={label}
          for={id}
          starBefore={required}
          tooltipMessage={labelTooltipMessage}
          tooltipPosition={labelTooltipPosition}
          tooltipColor={labelTooltipColor}
        />
      )}
      <Select
        id={id}
        isMulti={multiple}
        options={options}
        value={
          multiple
            ? options.filter(option => selectedValue?.includes(option.value))
            : options.find(option => option.value === selectedValue)
        }
        onChange={handleChange}
        className={
          'text-gray-400 focus:ring-purple-500 focus:border-purple-500 w-full '
          + inputClassName
        }
        classNamePrefix="react-select"
        placeholder={`Select ${label}`}
        isDisabled={disabled || readOnly}
        menuPortalTarget={document.body} // Render dropdown to the body
        styles={{
          menuPortal: base => ({ ...base, zIndex: 9999 }), // Ensure dropdown appears on top
        }}
      />
      {error && (
        <div className="text-red-500 text-xs">{error.message as string}</div>
      )}
    </div>
  );
};
