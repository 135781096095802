import React from 'react';
import {apiClient} from '@common/http/query-client';
import {getCookie} from 'react-use-cookie';

export interface Brand {
  id: number;
  name: string;
  description: string;
  created_at?: string;
  updated_at?: string;
}
export interface MainWebsiteBrand {
  brand_created_for_user_id: string;
  brand_desc: string;
  brand_logo: string;
  brand_name: string;
  date_added: string;
  id: string;
  parent_id: string;
  status: string;
  name?: string;
}

export default function useBrands() {
  const [brands, setBrands] = React.useState<MainWebsiteBrand[]>([]);

   async function getBrands(){
     /**
      * TODO!
      * we should not have static token even though if we don't have token in cookie, as
      * no one will do a direct login to the reskillify.co instead they will use
      * reskillify.com to login, so this means we will alwasy have a domain
      */
     const builder_token = getCookie('token', "28|mP5pxID0aD2a3aOoSwFRtCSMXXWXQGmaO8jXX3km975725df")

     apiClient.get(`third-party/brands?builder_token=${builder_token}`).then(brandsResponse => {
       const brandsData = brandsResponse['data'];

       if (!brandsData) {
         return;
       }

       setBrands(getProperBrands(getProperBrands(brandsData)));
     });
   }

  function getProperBrands(brands: MainWebsiteBrand[]){
    return brands.map((brand: MainWebsiteBrand) => ({
      name: brand.brand_name,
      ...brand,
    }))
  }

  return {
    brands,
    getBrands,
  }
}
