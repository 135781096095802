import StatusBadge from '@common/auth/ui/ito/components/status-badge';
import TableH from '@common/auth/ui/ito/components/table/TableH';
import TableD from '@common/auth/ui/ito/components/table/TableD';
import Table from '@common/auth/ui/ito/components/table/Table';
import TableHead from '@common/auth/ui/ito/components/table/TableHead';
import TableBody from '@common/auth/ui/ito/components/table/TableBody';
import TableTr from '@common/auth/ui/ito/components/table/TableTr';
import {Order} from '@common/ito/composables/useOrder';

interface ActivityTableProps {
  orders: Order[];
}

export function OrderTable({
                             orders
                           }: ActivityTableProps) {
    return (
      <Table>
        <TableHead>
          <TableTr>
            <TableH text="Amount" />
            <TableH text="Status" />
            <TableH text="Date" />
            <TableH text="Customer Name/Email" />
            <TableH text="Payment Method" />
            <TableH text="Payment Source" />
            <TableH text="Brand Name" />
          </TableTr>
        </TableHead>
        <TableBody>
          {orders.map((row, index) => (
            <TableTr key={index}>
              <TableD text={row.currency_symbol + row.total_amount} />
              <TableD>
                  <StatusBadge
                    status={row.status === 'paid'}
                    activeStatusText={row.status}
                    inactiveStatusText={row.status}
                  />
              </TableD>
              <TableD text={row.created_at} />
              <TableD>
                  <div className="flex items-center space-x-3">
                  <img
                    src={row.customer_avatar}
                    alt={row.customer_name}
                    className="rounded-full"
                    width={35}
                    height={35}
                  />
                  <div>
                    <p className="text-sm font-medium">{row.customer_name}</p>
                    <p className="text-xs text-gray-500">{row.customer_email}</p>
                  </div>
                  </div>
              </TableD>
              <TableD text={row.payment_method} />
              <TableD text={row.payment_source} />
              <TableD text={row.brand_name} />
            </TableTr>
          ))}
        </TableBody>
      </Table>
    );
}
