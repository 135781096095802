import {ComponentPropsWithoutRef} from 'react';
import {ITO_SidebarComponent} from '@common/ui/layout/ito/sidebar/sidebar-component';
import {ITO_Navbar} from '@common/ui/layout/ito/navbar/navbar-component';
import {ProgressCircle} from '@common/ui/progress/progress-circle';
import useSidebar from '@common/ui/layout/ito/sidebar/sidebar';
import useLayout from '@common/ui/layout/ito/layout';

interface LayoutProps extends ComponentPropsWithoutRef<'div'> {
  loading?: boolean;
  loadingText?: string;
}

export function ITO_Layout({loading=false, loadingText='Loading user...', children}: LayoutProps) {
  const {isSidebarOpen, isMobile, setIsSidebarOpen, toggleSidebar} = useSidebar()
  const {stylesLoaded} = useLayout();

  const layoutClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    const targetElement = e.target as HTMLElement;

    if (!targetElement.closest('.dashboard-sidebar') && isMobile && isSidebarOpen) {
      setIsSidebarOpen(false)
    }
  }

  if (!stylesLoaded) {
    return (
      <ProgressCircle
        className="my-80"
        aria-label="Loading UI..."
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          margin: '0',
        }}
        isIndeterminate
      />
    )
  }

  return (
    <>
      <style>{`
        .ito-tailwind-reset {
          all: unset;
        }
      `}</style>
      <div className="ito-tailwind-reset" onClick={layoutClickHandler}>
        <style>{`
          @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
          @font-face {
            font-family: 'InterFallback';
            src: local('Arial'); /* Use the local font available on the system */
            font-weight: normal;
            font-style: normal;
          }
          * {
            font-family: Inter, InterFallback;
          }
          .bg-purple-800 {
            background-color: #510487;
          }
          .hover\\:bg-purple-800:hover {
            background-color: #510487;
          }
          .text-purple-800 {
            color: #510487;
          }
          .hover\\:text-purple-800:hover {
            color: #510487;
          }
          .bg-purple-200 {
            background-color: #EEE6F4;
          }
          .hover\\:.bg-purple-200:hover {
            background-color: #EEE6F4;
          }
          .text-purple-200 {
            color: #9057B7 !important;
          }
          .hover\\:.text-purple-200:hover {
            color: #9057B7 !important;
          }
        `}</style>
        <div className="w-full max-w-full">
          <div className={`flex w-full max-w-full bg-[#F8F8FA]`}>
            <div
              className={`${
                (isSidebarOpen ? 'block' : 'hidden') +
                (isMobile ? ' fixed h-screen z-10' : ' static')
              } w-72 transition-all duration-300 flex-shrink-0`}
            >
              <ITO_SidebarComponent onToggleSidebar={toggleSidebar} />
            </div>
            <div style={{width: 'calc(100% - 18rem)'}} className="flex flex-1 flex-col">
              <ITO_Navbar onToggleSidebar={toggleSidebar} />
              <main className="p-5 relative" style={{minHeight: "calc(100vh - 73px)"}}>
                {loading ? (
                  <ProgressCircle
                    className="my-80"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      margin: '0',
                    }}
                    aria-label={loadingText}
                    isIndeterminate
                  />
                ) : (
                  <>{children}</>
                )}
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
