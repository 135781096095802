import { useLocation, useNavigate } from 'react-router-dom';
import React, { ReactElement, useEffect } from 'react';
import { AccountSettingsId } from '@common/ui/layout/ito/sidebar/sidebar';
import {Trans} from '@common/i18n/trans';
import routeNames from '@common/ito/route-names';

export interface Prop {
  to?: string;
  goto?: AccountSettingsId;
  Icon: ReactElement;
  title: string;
  onClick?: () => void;
}

export default function AccountSettingSidebarLink({ to, goto, Icon, title, onClick }: Prop) {
  const location = useLocation();
  const navigate = useNavigate();

  // Check if the current location is NOT /ito/account-settings
  const isNotAccountSettings = location.pathname !== routeNames.payments.settings.listing;

  // Handle navigation with scroll logic
  const handleNavigation = () => {
    if(onClick) onClick()

    if (isNotAccountSettings && to) {
      navigate(to); // Navigate to the target route
    }

    // Scroll to the element if `goto` is specified
    if (goto) {
      setTimeout(() => {
        const panelEl = document.querySelector(`#${goto}`);

        if (panelEl) {
          panelEl.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }, 200)
    }
  };

  return (
    <div
      onClick={handleNavigation}
      className="py-3 px-4 rounded-lg flex items-center cursor-pointer text-sm hover:bg-purple-800 hover:text-white"
    >
      <span className="mr-1.5">{Icon}</span>
      <Trans message={title} />
    </div>
  );
}
