import Breadcrumb from '@common/auth/ui/ito/breadcrumb';
import {Trans} from '@common/i18n/trans';
import {Link} from 'react-router-dom';
import routeNames from '@common/ito/route-names';

export default function ServicePageHeader() {
  const breadcrumbItems = [
    {label: 'Payments', path: routeNames.payments.gateway.listing},
    {label: 'Products & Services', path: ''},
  ];

  return (
    <>
      <Breadcrumb items={breadcrumbItems}/>

      <header className="sm:mt-7 mb-6 flex flex-col lg:flex-row items-start lg:items-center space-y-3 lg:space-y-0 justify-between">
        <div>
          <h2 className="text-xl font-semibold text-black">
            <Trans message='Products & Services' />
          </h2>
          <p className="text-gray-500 text-sm">
            <Trans message='See Listing of all of the products that are attached to a merchant account' />
          </p>
        </div>
        <div className="flex flex-wrap w-full sm:w-auto space-x-2">
          <Link to={routeNames.payments.services.create} className="flex-grow sm:flex-grow-0 text-center bg-purple-800 hover:bg-purple-900 py-1.5 rounded-md px-3 text-sm font-semibold text-white">
            <Trans message='+ Create Product' />
          </Link>
        </div>
      </header>
    </>
  );
}
