import {Trans} from '@common/i18n/trans';
import UiButton, {
  ButtonVariant
} from '@common/auth/ui/ito/components/ui-button';
import React, {useEffect, useRef} from 'react';

interface DialogProps {
  isOpen: boolean;
  title?: string;
  message?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm: () => void;
  onClose: () => void;
}

export function TailwindDialog({
                                 isOpen,
                                 title = 'Are you sure?',
                                 message = 'You wouldn\'t roll back this action!',
                                 confirmText = 'Done',
                                 cancelText = 'Cancel',
                                 onConfirm,
                                 onClose,
                               }: DialogProps) {
  const dialogRef = useRef<HTMLDivElement>(null);

  // Trap focus within the dialog when it is open
  useEffect(() => {
    if (isOpen) {
      const dialogElement = dialogRef.current;
      const focusableElements = dialogElement?.querySelectorAll<HTMLElement>(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      const firstElement = focusableElements?.[0];
      const lastElement = focusableElements?.[focusableElements.length - 1];

      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Tab') {
          if (e.shiftKey && document.activeElement === firstElement) {
            // If Shift+Tab is pressed on the first element, move focus to the last
            e.preventDefault();
            lastElement?.focus();
          } else if (!e.shiftKey && document.activeElement === lastElement) {
            // If Tab is pressed on the last element, move focus to the first
            e.preventDefault();
            firstElement?.focus();
          }
        } else if (e.key === 'Escape') {
          // Close the dialog when Escape is pressed
          onClose();
        }
      };

      // Set initial focus and add event listener
      firstElement?.focus();
      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div
      className="pointer-events-auto fixed inset-0 flex items-center justify-center bg-black bg-opacity-75"
      style={{zIndex: 1000}}
      onClick={onClose}
    >
      <div
        ref={dialogRef}
        onClick={(e) => e.stopPropagation()}
        className="pointer-events-auto relative w-96 rounded-lg bg-white p-6 shadow-lg"
        role="dialog"
        aria-modal="true"
        aria-labelledby="dialogTitle"
        aria-describedby="dialogMessage"
      >
        <h2 className="mb-4 text-xl font-semibold"><Trans message={title} /></h2>
        <p className="text-gray-600 mb-6">
          <Trans message={message} />
        </p>
        <div className="flex justify-end space-x-2">
          <UiButton
            variant={ButtonVariant.Secondary}
            onClick={onClose}
          >
            <Trans message={cancelText} />
          </UiButton>
          <UiButton
            id="closeDialog"
            variant={ButtonVariant.Primary}
            onClick={onConfirm}
          >
            <Trans message={confirmText} />
          </UiButton>
        </div>
      </div>
    </div>
  );
}
