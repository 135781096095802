import React from 'react';

interface UsePaymentGatewayTableProps {
  onDelete: (id: number) => void;
  onSort: (field: string) => void;
}

export default function usePaymentGatewayTable ({
                                                  onDelete,
                                                  onSort,
                                                }: UsePaymentGatewayTableProps) {
  const [deleteDialogOpened, setDeleteDialogOpened] = React.useState(false);
  const [selectedPaymentGatewayId, setSelectedPaymentGatewayId] = React.useState<number | null>(null);

  function openDeleteDialog(paymentGatewayId: number) {
    setDeleteDialogOpened(true);
    setSelectedPaymentGatewayId(paymentGatewayId)
  }
  const handleSortClick = (field: string) => {
    onSort(field);
  };

  function handleDeleteDialogClose() {
    setDeleteDialogOpened(false);
    setSelectedPaymentGatewayId(null)
  }

  function handleDeleteDialogConfirm() {
    if (selectedPaymentGatewayId) {
      onDelete(selectedPaymentGatewayId);
    }

    handleDeleteDialogClose()
  }

  return {
    deleteDialogOpened,
    handleSortClick,
    openDeleteDialog,
    handleDeleteDialogClose,
    handleDeleteDialogConfirm,
  }
}
