import React from 'react';

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Transparent = 'transparent',
}

export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  None = 'none',
}

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  children: React.ReactNode;
  className?: string;
}

export const variantStyles = {
  [ButtonVariant.Primary]:
    'bg-purple-800 hover:text-purple-800 hover:border-purple-800 hover:bg-transparent',
  [ButtonVariant.Secondary]:
    'bg-[#EEE6F4] text-purple-800 hover:border-purple-800 hover:bg-transparent border-transparent',
  [ButtonVariant.Success]:
    'text-green-500 border-green-500 hover:text-red-500 hover:border-red-500 group',
  [ButtonVariant.Danger]: 'text-red-500 border-red-500 hover:bg-red-100',
  [ButtonVariant.Transparent]:
    'bg-transparent text-gray-800 hover:text-gray-600 hover:bg-gray-100',
};

export const sizeStyles = {
  [ButtonSize.Small]: 'px-2 py-1 text-xs',
  [ButtonSize.Medium]: 'px-3 py-1.5 text-sm',
  [ButtonSize.Large]: 'px-4 py-2 text-base',
  [ButtonSize.None]: 'p-0 text-sm',
};

const UIButton: React.FC<ButtonProps> = ({
  variant = ButtonVariant.Primary,
  size = ButtonSize.Medium,
  icon,
  iconPosition = 'left',
  children,
  className = '',
  ...props
}) => {
  const baseStyles =
  'inline-flex items-center gap-x-2 whitespace-nowrap rounded-md border-2 border-transparent text-sm font-semibold text-white transition-all';

  return (
    <button
      className={`${baseStyles} ${variantStyles[variant]} ${sizeStyles[size]} ${className}`}
      {...props}
    >
      {icon && iconPosition === 'left' && <>{icon}</>}
      {children}
      {icon && iconPosition === 'right' && <>{icon}</>}
    </button>
  );
};

export default UIButton;
