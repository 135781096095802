import {Trans} from '@common/i18n/trans';
import SidebarTogglerIcon from '@common/ui/layout/ito/navbar/sidebar-toggler-icon';
import {useSettings} from '@common/core/settings/use-settings';
import {useAuth} from '@common/auth/use-auth';
import NavbarNotifications
  from '@common/ui/layout/ito/navbar/navbar-notifications';
import NavbarUserProfile
  from '@common/ui/layout/ito/navbar/navbar-user-profile';

interface NavbarProps {
  onToggleSidebar: () => void;
}

export function ITO_Navbar({ onToggleSidebar }: NavbarProps) {
  const {isLoggedIn} = useAuth();
  const {notifications} = useSettings();
  const showNotifButton = isLoggedIn && notifications?.integrated;

  return (
    <div className="flex items-center justify-between border-b bg-white px-4 py-2 shadow-sm">
      <div
        onClick={onToggleSidebar}
        className="hover:text-purple-800 flex cursor-pointer items-center"
      >
        <div className="mr-1">
          <SidebarTogglerIcon />
        </div>
        <span className="text-md ml-2 font-semibold">
          <Trans message='Dashboard' />
        </span>
      </div>

      {/* User Profile & Notification */}
      <aside className="relative flex items-center">
        {showNotifButton && <NavbarNotifications />}
        <NavbarUserProfile />
      </aside>
    </div>
  );
}
