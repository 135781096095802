import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

export enum AccountSettingsId {
  AccountDetails = 'account-details',
  SocialLogin = 'social-login',
  Password = 'password',
  TwoFactor = 'two-factor',
  LocationAndLanguage = 'location-and-language',
  Developers = 'developers',
  DeleteAccount = 'delete-account',
  Sessions = 'sessions',
  Payment = 'payment',
}

export default function useSidebar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isPaymentsOpen, setIsPaymentsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const location = useLocation();
  const isPaymentsRelated = location?.pathname?.includes('/payments') || false

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const togglePayments = () => {
    setIsPaymentsOpen(!isPaymentsOpen);
  };

  const closePaymentsDropdown = () => {
    setIsPaymentsOpen(false)
  }

  useEffect(() => {
    if (isPaymentsRelated && !isPaymentsOpen) {
      setIsPaymentsOpen(true)
    }
  }, [isPaymentsRelated, isPaymentsOpen]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 768) {
        setIsSidebarOpen(true)
        setIsMobile(false)
      } else {
        setIsSidebarOpen(false)
        setIsMobile(true)
      }
    }

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isSidebarOpen,
    isPaymentsOpen,
    isPaymentsRelated,
    isMobile,
    toggleSidebar,
    setIsSidebarOpen,
    togglePayments,
    closePaymentsDropdown,
  }
}
