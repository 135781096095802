import React from 'react';

interface AngleIconProps {
  down?: boolean;
  className?: string;
}

export default function AngleIcon({down = false, className='h-5 w-5'}: AngleIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} transform ${
        down ? 'rotate-180' : ''
      }`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 9l-7 7-7-7"
      />
    </svg>
  )
}
