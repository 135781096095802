import BellIcon from '@common/ui/layout/ito/navbar/bell-icon';
import useITONavbar from '@common/ui/layout/ito/navbar/navbar';
import {Trans} from '@common/i18n/trans';
import ZeroNotificationsIcon
  from '@common/ui/layout/ito/navbar/zero-notifications-icon';
import {useAuth} from '@common/auth/use-auth';
import {
  useUserNotifications
} from '@common/notifications/dialog/requests/user-notifications';
import {ProgressCircle} from '@common/ui/progress/progress-circle';
import {NotificationList} from '@common/notifications/notification-list';

export default function NavbarNotifications() {
  const {user} = useAuth();
  const hasUnread = !!user?.unread_notifications_count;
  const {
    isNotificationDropdownOpen,
    notificationDropdownRef,
    toggleNotificationDropdown,
    setIsNotificationDropdownOpen,
  } = useITONavbar();

  return (
    <section className="">

      {/*
        we have error when click on toggleNotificationDropdown (if have notifications),
        so we should solve this:
      */}
      <button
        onClick={toggleNotificationDropdown}
        className="relative p-2 focus:outline-none"
      >
        {hasUnread && (
          <span
            className="bg-red-500 absolute right-1 top-1 flex h-4 w-4 items-center justify-center rounded-full border-2 border-white text-xs text-white">
            {user?.unread_notifications_count}
          </span>
        )}
        <BellIcon />
      </button>
      {/* Notification Dropdown */}
      {isNotificationDropdownOpen && (
        <div
          ref={notificationDropdownRef}
          className="border-gray-200 absolute right-0 z-30 mt-2 w-96 rounded-md border bg-white shadow-lg"
        >
          <header
            className="mb-4 flex items-center justify-between border-b-2 px-4 py-3">
            <span className="text-md font-semibold">
              <Trans message="Notifications" />
              {hasUnread ? ` (${user?.unread_notifications_count})` : undefined}
            </span>
            <button
              onClick={() => setIsNotificationDropdownOpen(false)}
              className="text-gray-500 hover:text-gray-700 text-2xl focus:outline-none"
            >
              &times;
            </button>
          </header>

          {/* Illustration & Message */}
          <NotificationDropdownItems />
        </div>
      )}
    </section>
  );
}

function EmptyNotificationMessage() {
  return (
    <div className="flex flex-col items-center justify-center px-6 py-4">
      <div className="mb-4">
        <ZeroNotificationsIcon />
      </div>
      <p className="text-gray-600 font-medium">
        <Trans message="Hang tight!" />
      </p>
      <p className="text-gray-500 text-sm">
        <Trans message="Notifications will start showing up here soon." />
      </p>
    </div>
  );
}

function NotificationDropdownItems() {
  const {data, isLoading} = useUserNotifications();
  if (isLoading) {
    return (
      <div className="flex items-center justify-center px-24 py-20">
        <ProgressCircle aria-label="Loading notifications..." isIndeterminate />
      </div>
    );
  }

  if (!data?.pagination.data.length) {
    return (
      <EmptyNotificationMessage />
    );
  }

  return (
    <div>
      <NotificationList notifications={data.pagination.data} />
    </div>
  );
}
