import {
  ITO_PaymentGatewayForm
} from '@common/auth/ui/ito/payments/gateway/payment-gateway-form';
import routeNames from '@common/ito/route-names';

export function ITO_CreatePaymentGatewayPage() {
  const breadcrumbItems = [
    {label: 'Payment Gateway', path: routeNames.payments.gateway.listing},
    {label: 'Choose Method', path: routeNames.payments.gateway.choose},
    {label: 'Add Current Payment Gateway', path: ''},
  ];

  return (
    <ITO_PaymentGatewayForm
      breadcrumbItems={breadcrumbItems}
    />
  );
}
