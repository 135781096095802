import {Trans} from '@common/i18n/trans';
import {
  ChooseProviderSelectBox, ChooseProviderSelectBoxProps
} from '@common/auth/ui/ito/payments/gateway/choose-payment-gateway/choose-provider-select-box';
import React from 'react';

interface AddPaymentGatewaySectionProps extends ChooseProviderSelectBoxProps {}

export function AddPaymentGatewaySection({ onProviderChange }: AddPaymentGatewaySectionProps) {
  return (
    <section className="mb-8 px-5 py-4 rounded-lg bg-white shadow-sm">
      <header>
        <h2
          className="border-gray-100 text-md mb-3 border-b pb-1 font-semibold">
          <Trans message="Add Payment Gateway" />
        </h2>
      </header>
      <main>
        <ChooseProviderSelectBox onProviderChange={onProviderChange} />
      </main>
    </section>
  )
}
