import {BootstrapData} from './bootstrap-data';
import {createContext, useContext, useEffect, useState} from 'react';
import {apiClient} from '@common/http/query-client';

export interface BoostrapDataContextValue<T = BootstrapData> {
  data: T;
  setBootstrapData: (data: string | T) => void;
  mergeBootstrapData: (data: Partial<T>) => void;
  invalidateBootstrapData: () => void;
}

export const BoostrapDataContext = createContext<BoostrapDataContextValue>(
  null!
);

export function useBootstrapData() {
  return useContext(BoostrapDataContext);
}








// TODO!
// checking....
export function useRehydrateBootstrapData() {
  const [isLoading, setIsLoading] = useState(true);
  const {setBootstrapData} = useBootstrapData();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      apiClient
        .get('/bootstrap-data')
        .then(response => {
          setBootstrapData(response.data);
        })
        .catch(() => {
          console.error('Failed to rehydrate bootstrap data');
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [setBootstrapData]);

  return {isLoading, isRehydrating: isLoading};
}
