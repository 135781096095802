import React, {useEffect, useState} from 'react';

interface SwitcherProps {
  Before: React.ReactNode;
  After: React.ReactNode;
  onChange?: (checked: boolean) => void;
  checked?: boolean
}

export default function Switcher ({
  checked = false,
  onChange,
  Before,
  After
}: SwitcherProps) {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const onChangeHandler = () => {
    setIsChecked(!isChecked)

    if (onChange) onChange(!isChecked)
  }

  return (
    <>
      <style>{`
        input[type='checkbox']:checked + div {
          background-color: #6b21a8;
        }

        input[type='checkbox']:checked + div > div {
          transform: translateX(1.25rem);
        }
      `}</style>
      <label className="inline-flex cursor-pointer select-none items-center">
        {Before && <span className='mr-1'>{Before}</span>}
        <div className="relative">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={onChangeHandler}
            className="sr-only"
            aria-hidden="true"
          />
          <div
            onClick={onChangeHandler}
            className="block h-8 w-14 rounded-full bg-[#E5E7EB]"></div>
          <div
            onClick={onChangeHandler}
            className={`dot absolute left-1 top-1 h-6 w-6 rounded-full bg-white transition-transform ${
              isChecked ? 'translate-x-6' : ''
            }`}
          ></div>
        </div>
        {After && <span className='ml-1'>{After}</span>}
      </label>
    </>
  );
}
