import React, {ReactNode} from 'react';
import {Trans} from '@common/i18n/trans';
import Tooltip, {TooltipPosition} from '@common/auth/ui/ito/components/tooltip';
import {IHelpIcon} from '@common/auth/ui/ito/icons/i-help-icon';

interface FieldLabelProps {
  for?: string;
  label: string | ReactNode;
  starBefore?: boolean;
  starAfter?: boolean;
  tooltipMessage?: string;
  tooltipPosition?: TooltipPosition;
  tooltipColor?: string;
}

export default function FieldLabel({
  label,
  for: forHTML = '',
  starBefore = undefined,
  starAfter = undefined,
  tooltipMessage = undefined,
  tooltipPosition = TooltipPosition.Top,
  tooltipColor = 'bg-gray-600',
}: FieldLabelProps) {
  return (
    <label
      htmlFor={forHTML}
      className="text-gray-800 mb-2 flex text-sm font-semibold"
    >
      {starBefore && <span className="text-red-500">*</span>}
      {typeof label === 'string' ? <Trans message={label} /> : <>{label}</>}

      {tooltipMessage && tooltipPosition && (
        <Tooltip
          message={tooltipMessage}
          position={tooltipPosition}
          color={tooltipColor}
          className="w-max"
          Trigger={<IHelpIcon className="text-gray-300 ml-1 h-4 w-4" />}
        />
      )}
      {starBefore === undefined && starAfter && (
        <span className="text-red-500"> *</span>
      )}
    </label>
  );
}
