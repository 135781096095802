type RouteNames = {
  payments: {
    dashboard: {
      listing: string;
    },
    gateway: {
      listing: string;
      create: (name: string) => string;
      edit: (gatewayId: number) => string;
      choose: string;
    };
    profile: {
      listing: string;
    };
    services: {
      listing: string;
      create: string;
      edit: (serviceId: number) => string;
    };
    methods: {
      listing: string;
    };
    settings: {
      listing: string;
    };
    orders: {
      listing: string;
    }
  };
};

const buildRoutes = (): RouteNames => {
  const dashboardPrefix = '/payments/dashboard';
  const gatewayPrefix = '/payments/gateway';
  const profilePrefix = '/payments/profile';
  const servicesPrefix = '/payments/services';
  const methodPrefix = '/payments/methods';
  const settingsPrefix = '/account-settings';
  const orderPrefix = '/payments/orders';

  return {
    payments: {
      dashboard: {
        listing: dashboardPrefix,
      },
      gateway: {
        listing: gatewayPrefix,
        create: (name: string) => `${gatewayPrefix}/${name}/create`,
        edit: (gatewayId: number) => `${gatewayPrefix}/${gatewayId}/edit`,
        choose: `${gatewayPrefix}/choose`,
      },
      profile: {
        listing: profilePrefix,
      },
      services: {
        listing: servicesPrefix,
        create: `${servicesPrefix}/create`,
        edit: (serviceId: number) => `${servicesPrefix}/${serviceId}/edit`,
      },
      methods: {
        listing: methodPrefix,
      },
      settings: {
        listing: settingsPrefix,
      },
      orders: {
        listing: orderPrefix,
      },
    },
  };
};


const routeNames = buildRoutes()


export default routeNames;
