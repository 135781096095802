import {AccountSettingsId} from '@common/ui/layout/ito/sidebar/sidebar';
import {Trans} from '@common/i18n/trans';

export function ITO_DangerzonePanel() {
  const buttonDisabled = true;

  return (
    <div
      id={AccountSettingsId.DeleteAccount}
      className="rounded-lg bg-white p-6 shadow">
      <h2 className="mb-4 border-b pb-2 text-lg font-semibold">
        <Trans message="Danger zone" />
      </h2>
      <button
        className={`rounded-lg px-4 py-2 text-sm ${
          buttonDisabled
            ? "bg-gray-200 text-gray-500 cursor-not-allowed"
            : "bg-red-50 text-red-500 hover:bg-red-700"
        }`}
        disabled={true}
      >
        <Trans message="Delete Account" />
      </button>
    </div>
  )
}
