import React, {useEffect} from 'react';
import Breadcrumb from '@common/auth/ui/ito/breadcrumb';
import {Trans} from '@common/i18n/trans';
import  {TooltipPosition} from '@common/auth/ui/ito/components/tooltip';
import UIButton, {
  ButtonVariant,
} from '@common/auth/ui/ito/components/ui-button';
import {Form} from '@common/ui/forms/form';
import InputField from '@common/auth/ui/ito/components/input-field';
import {SelectField} from '@common/auth/ui/ito/components/select-field';
import {useParams} from 'react-router-dom';
import useServiceForm
  from '@common/auth/ui/ito/payments/services/use-service-form';
import {
  ServiceFormValues
} from '@common/auth/ui/ito/payments/services/edit/update-service';
import TextAreaField from '@common/auth/ui/ito/components/textarea-field';
import FileUploadDropzone from './file-upload-dropzone';
// import {RefundIcon} from '@common/auth/ui/ito/icons/refund-icon';
import FieldLabel from '@common/auth/ui/ito/components/field-label';
import routeNames from '@common/ito/route-names';
import UILink from '@common/auth/ui/ito/components/ui-link';
import {ProgressCircle} from '@common/ui/progress/progress-circle';
import {StaticPageTitle} from '@common/seo/static-page-title';

interface ServiceFormProps {
  breadcrumbItems: {label: string, path: string}[];
}

export function ITO_ServiceForm({
  breadcrumbItems,
}: ServiceFormProps) {
  const {serviceId} = useParams();
  const {
    isLoading,
    currencies,
    brands,
    form,
    // selectedRecurring,
    addonSelected,
    // toggleAddonSelection,
    setSelectedRecurring,
    onSubmit,
    currentCurrency,
  } = useServiceForm({serviceId});

  const uploadedImage = form.watch('image') || '';

  const handleRemoveUploadedImage = async () => {
    form.setValue('image', '');
    await form.trigger('image');
  };

  function onFormSubmit (data: ServiceFormValues) {
    const sanitizedData = {
      ...data,
      image: data.image || '',
      upsell: data.upsell ? 1 : 0,
      bump: data.bump ? 1 : 0,
      one_time_order: data.one_time_order ? 1 : 0,
      affiliate_commission: data.affiliate_commission ? 1 : 0,
    };

    if (!serviceId) {
      return onSubmit(sanitizedData)
    }

    return onSubmit({...sanitizedData, id: Number(serviceId)})
  }

  const handleRadioChange = (value: number) => {
    setSelectedRecurring(value);
    form.setValue('recurring', value);
  };

  return (
    <div>
      <StaticPageTitle>
        <Trans message={`${serviceId ? 'Edit' : 'Add'} Product`} />
      </StaticPageTitle>
      <Breadcrumb items={breadcrumbItems} className="mb-5" />
      <header className="sm:mt-7 mb-6 flex flex-col items-start justify-between space-y-3 lg:flex-row lg:space-y-0">
        <div className="w-1/2">
          <h2 className="mb-3 text-xl font-semibold text-black">
            <Trans message={(serviceId ? 'Edit' : 'Add') + ' Product'} />
          </h2>
          <p className="text-gray-500 text-sm">
            <Trans message="What do you want to link?" />
          </p>
        </div>
        {/*<div className="flex gap-x-2">*/}
        {/*  <UIButton variant={ButtonVariant.Primary} icon={<RefundIcon />}>*/}
        {/*    <Trans message="Refund" />*/}
        {/*  </UIButton>*/}
        {/*</div>*/}
      </header>
      <main>
        {isLoading ? (
          <ProgressCircle
            className="my-80"
            size='sm'
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              margin: '0',
            }}
            aria-label='Loading payment gateways...'
            isIndeterminate
          />
          ) : (
          <Form
            form={form}
            id="gateway-form"
            className="space-y-8"
            onSubmit={onFormSubmit}
          >
            {/*{JSON.stringify(form.getValues('recurring'))}*/}
            <section className="space-y-6 rounded -lg bg-white px-5 py-6 shadow-sm">
              <article className="grid grid-cols-1 gap-6 md:grid-cols-2">
                <div className="flex flex-col gap-y-5">
                  <SelectField
                    form={form}
                    name="service_type"
                    id="service_type"
                    label="Service Type"
                    options={[
                      {id: 1, name: 'Course'},
                      {id: 2, name: 'Clinic'},
                      {id: 3, name: 'Digital Product'},
                      {id: 4, name: 'Community Membership'},
                    ].map(serviceType => ({
                      value: serviceType.name,
                      label: serviceType.name,
                    }))}
                    required={true}
                  />
                  <SelectField
                    form={form}
                    name="brand_id"
                    id="brand_id"
                    label="Brand"
                    options={brands.map(brand => ({
                      value: brand.id,
                      label: brand.name as string,
                    }))}
                    required={true}
                  />
                  <InputField
                    form={form}
                    name="name"
                    id="name"
                    label="Product/Service name"
                    placeholder="Enter Product/Service Name"
                    required={true}
                  />
                </div>
                <div className="rounded-lg border shadow">
                  <FileUploadDropzone
                    allowedFormats={['JPEG', 'JPG', 'PNG']}
                    maxFileSizeMB={25}
                    uploadedImage={uploadedImage}
                    onFileUpload={(file) => {
                      form.setValue('image', file)

                      return;
                    }}
                    onRemoveUploadedImage={handleRemoveUploadedImage}
                  />
                </div>
              </article>
              <article className="grid grid-cols-1 gap-6 md:grid-cols-2">
                <TextAreaField
                  form={form}
                  name="descriptions"
                  id="descriptions"
                  label="Description"
                  placeholder="Write note here"
                  rows={3}
                  required={true}
                />
                <TextAreaField
                  form={form}
                  name="statement_descriptions"
                  id="statement_descriptions"
                  label="Statement Descriptor"
                  placeholder="Write descriptor here"
                  rows={3}
                />
              </article>
              <article className="grid grid-cols-1 gap-6 md:grid-cols-2">
                <InputField
                  form={form}
                  name="product_id_on_reskillify"
                  id="product_id_on_reskillify"
                  label="Product ID on ReSkillify.com"
                  placeholder="Reskillify product id"
                  labelTooltipMessage="This is the product id on Reskillify.com"
                  labelTooltipPosition={TooltipPosition.Top}
                />
                <div className="flex gap-x-2">
                  <InputField
                    label="sku"
                    form={form}
                    id="sku"
                    name="sku"
                    placeholder="Enter SKU"
                    className="flex-grow"
                  />
                  <InputField
                    type="number"
                    label="Quantity"
                    form={form}
                    id="quantity"
                    name="quantity"
                    placeholder="1"
                    className="w-20"
                  />
                </div>
              </article>
              <article className="grid grid-cols-1 items-start gap-6 gap-x-3 md:grid-cols-5">
                <div>
                  <FieldLabel
                    label="Recurring"
                    for="recurring-on"
                    tooltipMessage="Recurring or not?"
                  />
                  <div className="flex items-center space-x-4 rounded-md border px-4 py-2">
                    <div className="flex items-center space-x-2">
                      {/*
                        // TODO!
                        // checking....
                        // didn't work properly, i.e. not checked
                      */}
                      {/*{...form.register('recurring')}*/}
                      {/*value: {form.getValues('recurring')}*/}
                      <input
                        {...form.register('recurring')}
                        type="radio"
                        id="recurring-on"
                        name="recurring"
                        className="accent-purple-600"
                        value={1}
                        checked={form.getValues('recurring') == 1}
                      />
                      <label
                        htmlFor="recurring-on"
                        className="text-gray-800 cursor-pointer"
                      >
                        On
                      </label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <input
                        {...form.register('recurring')}
                        type="radio"
                        id="recurring-off"
                        name="recurring"
                        className="accent-purple-600"
                        value={0}
                      />
                      <label
                        htmlFor="recurring-off"
                        className="text-gray-800 cursor-pointer"
                      >Off</label>
                    </div>
                  </div>
                </div>
                <SelectField
                  label="Select Duration"
                  id="duration"
                  name="duration"
                  form={form}
                  options={[
                    {id: 1, name: 'Monthly'},
                    {id: 2, name: 'Yearly'},
                  ].map(duration => ({
                    value: duration.name,
                    label: duration.name,
                  }))}
                  className="col-span-2"
                />
                <SelectField
                  label="Choose Your Pricing Model"
                  id="pricing_model"
                  name="pricing_model"
                  form={form}
                  options={[{id: 1, name: 'Flat-Rate'},{id:2, name: 'Tiered'},{id:3,  name: 'Pay-As-You-Go'},{id:4, name: 'Subscription'},{id:5, name: 'One-Time'}].map(pricingModel => ({
                    value: pricingModel.name,
                    label: pricingModel.name,
                  }))}
                  className="col-span-2"
                />
              </article>
              <article className="grid grid-cols-1 gap-6 md:grid-cols-2">
                <div>
                  <FieldLabel label="Amount (required)" for="amount" />
                  <div className="flex h-10 items-center rounded-md border px-3">
                  <span className="text-gray-900">
                    {currentCurrency()?.symbol ?? '$'}
                  </span>
                    <input
                      {...form.register('amount', {
                        required: `Amount is required`,
                      })}
                      id="amount"
                      name="amount"
                      type="number"
                      placeholder="0.00"
                      className="text-gray-700 placeholder-gray-400 mx-2 flex-1 border-none focus:outline-none focus:ring-0"
                    />

                    {/* Divider */}
                    <div className="mx-2 h-full border-l"></div>

                    <select
                      {...form.register('currency_id', {
                        required: `Currency is required`,
                      })}
                      name="currency_id"
                      id="currency_id"
                      className="text-gray-700 border-none bg-transparent focus:outline-none focus:ring-0"
                      defaultValue="USD"
                    >
                      <option value="" disabled></option>
                      {currencies.map(t => (
                        <option key={t.id} value={t.id}>
                          {t.code}
                        </option>
                      ))}
                    </select>
                  </div>
                  {(form?.formState?.errors?.currency_id && (
                    <div className="text-red-500 text-xs">
                      {form?.formState?.errors?.currency_id?.message}
                    </div>
                  ))}
                </div>
                <InputField
                  form={form}
                  type='number'
                  name="sale_price"
                  id="sale_price"
                  label="Sale Price ($)"
                  placeholder="Enter Price"
                  labelTooltipMessage="Calendar"
                  labelTooltipPosition={TooltipPosition.Top}
                />
              </article>
              <article className="grid grid-cols-1 gap-6">
                <TextAreaField
                  form={form}
                  name="purchase_note"
                  id="purchase_note"
                  label="Purchase note"
                  placeholder="Write descriptor here"
                  rows={4}
                  labelTooltipMessage="This is the product id on Reskillify.com"
                />
              </article>
              <article className="grid grid-cols-1 gap-6 xl:grid-cols-2">
                <div>
                  <FieldLabel
                    label="Can this product or service also be sold as an"
                    tooltipMessage="Can this product or service also be sold as an"
                    for="check"
                  />

                  <div className="flex items-center space-x-4 rounded-md border px-4 py-2">
                    <label
                      className={`flex cursor-pointer items-center space-x-2 ${
                        addonSelected.includes('Upsell')
                          ? 'text-purple-600'
                          : 'text-gray-600'
                      }`}
                      htmlFor="upsell"
                    >
                      <input
                        {...form.register('upsell')}
                        id="upsell"
                        name="upsell"
                        type="checkbox"
                        value={1}
                        className="accent-purple-600"
                      />
                      <span>Upsell</span>
                    </label>

                    <label
                      className={`whitespace-nowrap flex cursor-pointer items-center space-x-2 ${
                        addonSelected.includes('Bump')
                          ? 'text-purple-600'
                          : 'text-gray-600'
                      }`}
                    >
                      <input
                        {...form.register('bump')}
                        id="bump"
                        name="bump"
                        type="checkbox"
                        value={1}
                        className="accent-purple-600"
                      />
                      <span>Bump</span>
                    </label>

                    <label
                      className={`whitespace-nowrap flex cursor-pointer items-center space-x-2 ${
                        addonSelected.includes('OTO: One Time Order')
                          ? 'text-purple-600'
                          : 'text-gray-600'
                      }`}
                    >
                      <input
                        {...form.register('one_time_order')}
                        id="one_time_order"
                        name="one_time_order"
                        type="checkbox"
                        value={1}
                        className="accent-purple-600"
                      />
                      <span>One Time Order</span>
                    </label>

                    <label
                      className={`whitespace-nowrap flex cursor-pointer items-center space-x-2 ${
                        addonSelected.includes('Affiliate Commissions')
                          ? 'text-purple-600'
                          : 'text-gray-600'
                      }`}
                    >
                      <input
                        {...form.register('affiliate_commission')}
                        id="affiliate_commission"
                        name="affiliate_commission"
                        type="checkbox"
                        value={1}
                        className="accent-purple-600"
                      />
                      <span>Affiliate Commissions</span>
                    </label>
                  </div>
                </div>
              </article>

              <div className="mt-4 flex justify-end gap-4">
                <UILink to={routeNames.payments.services.listing} variant={ButtonVariant.Secondary}>
                  <Trans message="Cancel" />
                </UILink>
                <UIButton>
                  <Trans message={`${serviceId ? 'Edit' : 'Add'} Product`} />
                </UIButton>
              </div>
            </section>
          </Form>
        )}
      </main>
    </div>
  );
}
