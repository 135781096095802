import React, {ReactNode} from 'react';

interface TableHeadProps {
  className?: string;
  children: ReactNode;
}

export default function TableHead ({
  children,
  className = '',
}: TableHeadProps) {
  return (
    <thead className={`bg-gray-100 text-sm text-black ${className}`}>
      {children}
    </thead>
  );
}
