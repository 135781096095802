import AngleBottomIcon from '@common/ui/layout/ito/navbar/angle-bottom-icon';
import {Link} from 'react-router-dom';
import {Trans} from '@common/i18n/trans';
import useITONavbar from '@common/ui/layout/ito/navbar/navbar';
import {useCustomMenu} from '@common/menus/use-custom-menu';

export default function NavbarUserProfile() {
  const {
    // themes,
    // selectedTheme,
    // auth,
    user,
    fullName,
    isSubscribed,
    isDropdownOpen,
    profileDropdownRef,
    logout,
    // selectTheme,
    toggleDropdown,
  } = useITONavbar();

  return (
    <section className="relative ml-4">
      <button
        id="user-menu-button"
        onClick={toggleDropdown}
        className="flex items-center focus:outline-none"
        type="button"
      >
        <span className="mr-3 text-sm font-medium">{`${fullName}`}</span>
        <img
          className="h-14 w-14 rounded-full shadow"
          src={user?.avatar ? user.avatar : "/images/avatar.png"}
          alt="User Avatar"
        />
        <AngleBottomIcon />
      </button>

      {/* Profile Dropdown */}
      {isDropdownOpen && (
        <div
          ref={profileDropdownRef}
          className="border-gray-200 absolute right-0 z-20 mt-2 w-48 rounded-md border bg-white py-1 shadow-lg"
        >
          {isSubscribed && (
            <ListItemLink
              to="/billing"
              text="Billing"
            />
          )}

          <CommonMenuItems />

          {/*
            TODO!
            un comment the following line
          */}
            {/*<ListItemLink*/}
            {/*  to="#"*/}
            {/*  text="Dark Mode"*/}
            {/*/>*/}
          {/*{themes?.user_change && !selectedTheme.is_dark && (*/}
          {/*  <MenuItem*/}
          {/*    value="light"*/}
          {/*    startIcon={<DarkModeIcon />}*/}
          {/*    onSelected={() => {*/}
          {/*      selectTheme('dark');*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <Trans message="Dark mode" />*/}
          {/*  </MenuItem>*/}
          {/*)}*/}
          {/*{themes?.user_change && selectedTheme.is_dark && (*/}
          {/*  <MenuItem*/}
          {/*    value="dark"*/}
          {/*    startIcon={<LightModeIcon />}*/}
          {/*    onSelected={() => {*/}
          {/*      selectTheme('light');*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <Trans message="Light mode" />*/}
          {/*  </MenuItem>*/}
          {/*)}*/}
          <ListItemLink
            to="#"
            text="Log out"
            onClick={() => {
              logout.mutate();
            }}
          />
        </div>
      )}
    </section>
  );
}

interface ListItemLinkProps {
  to: string;
  text: string;
  className?: string;
  onClick?: () => void
}
function ListItemLink ({
  to,
  text,
  className = "text-gray-700 hover:bg-gray-100 block px-4 py-2",
  onClick = () => {},
}: ListItemLinkProps) {
  return (
    <>
      <Link
        to={to}
        className={className}
        onClick={onClick}
      >
        <Trans message={text} />
      </Link>
    </>
  )
}


function CommonMenuItems() {
  const menu = useCustomMenu('auth-dropdown');

  return (
    menu?.items?.map(item => {
      return (
        <ListItemLink
          to={item.action}
          text={item.label}
          key={item?.action ?? item.label}
        />
      )
    })
  )
}
