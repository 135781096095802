import { UseMutationResult } from '@tanstack/react-query';
import { UseFormReturn } from 'react-hook-form';
import useUpdateService, {
  ServiceFormValues
} from '@common/auth/ui/ito/payments/services/edit/update-service';
import useCreateService
  from '@common/auth/ui/ito/payments/services/create/create-service';

interface ServiceActionProps {
  form: UseFormReturn<any>;
  serviceId?: number | string;
}

export function useServiceAction({
  form,
  serviceId,
}: ServiceActionProps): any | UseMutationResult<
  Response,
  Error,
  ServiceFormValues,
  unknown
> {
  const updateMutation = serviceId
    ? useUpdateService({ form, serviceId })
    : null;
  const createMutation = !serviceId
    ? useCreateService({ form })
    : null;

  // Return the active mutation based on the existence of serviceId
  return serviceId ? updateMutation! : createMutation!;
}
