import {ReactNode} from 'react';

export enum BadgeType
{
  Success = 'success',
  Danger = 'danger',
  Error = 'danger',
  Warning = 'warning',
  Secondary = 'secondary',
}

interface BadgeProps {
  type?: BadgeType;
  children: ReactNode;
  dot?: boolean;
}

export default function Badge({
  type = BadgeType.Secondary,
  dot = true,
  children,
}: BadgeProps) {

  const badgeBackgroundColor = () => {
    switch (type) {
      case BadgeType.Success:
        return 'bg-green-500';
      case BadgeType.Danger:
      case BadgeType.Error:
        return 'bg-red-500';
      case BadgeType.Warning:
        return 'bg-yellow-500';
      default:
        return 'bg-gray-500';
    }
  };

  return (
    <button
      className="px-1.5 py-0.5 inline-flex items-center justify-around rounded-md border text-xs">
      {dot && (
        <span
          className={`mr-1 inline-block h-2 w-2 rounded-full ${badgeBackgroundColor()}`}
        ></span>
      )}
      <span
        className="capitalize">{children}</span>
    </button>
  );
}
