import { UseMutationResult } from '@tanstack/react-query';
import { UseFormReturn } from 'react-hook-form';
import useUpdatePaymentGateway, {
  PaymentGatewayFormValues
} from '@common/auth/ui/ito/payments/gateway/edit/update-payment-gateway';
import useCreatePaymentGateway
  from '@common/auth/ui/ito/payments/gateway/create/create-payment-gateway';

interface PaymentGatewayActionProps {
  form: UseFormReturn<any>;
  paymentGatewayId?: number | string;
}

export function usePaymentGatewayAction({
  form,
  paymentGatewayId,
}: PaymentGatewayActionProps): null | UseMutationResult<
  Response,
  Error,
  PaymentGatewayFormValues,
  unknown
> {
  const updateMutation = paymentGatewayId
    ? useUpdatePaymentGateway({ form, paymentGatewayId })
    : null;
  const createMutation = !paymentGatewayId
    ? useCreatePaymentGateway({ form })
    : null;

  // Return the active mutation based on the existence of paymentGatewayId
  //@ts-ignore
  return paymentGatewayId ? updateMutation! : createMutation!;
}
