export function ITO_PaymentProductAndServicesSell() {
    return (
        <div className="p-6 max-w-1xl mx-auto bg-white shadow-md rounded-md">
            {/* Header */}
            <div className="flex space-x-4 mb-6 justify-center">
            <button className="bg-gray-600 text-white px-6 py-3 rounded hover:bg-gray-200">
            Add Product
            </button>
            <button className="bg-purple-600 text-white px-6 py-3 rounded hover:bg-purple-700">
            Create Product
            </button>
        </div>
  
        {/* Section: Product Type */}
        <div>
            <h1 className="text-xl font-semibold mb-4">
                What type of product or service would you like to sell?
            </h1>
            <select
                className="w-full p-3 border border-gray-300 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-purple-500">
                    <option>Search for a product...</option>
                    <option>Course</option>
                    <option>Clinic</option>
                    <option>Digital Product</option>
            </select>
    
            <div className="flex items-center space-x-4 mb-4">
                <label className="flex items-center space-x-2">
                <input
                    type="checkbox"
                    className="form-checkbox text-purple-600 border-gray-300 rounded"
                />
                <span className="text-gray-700">Downloadable</span>
                </label>
                <label className="flex items-center space-x-2">
                <input
                    type="checkbox"
                    className="form-checkbox text-purple-600 border-gray-300 rounded"
                />
                <span className="text-gray-700">Virtual</span>
                </label>
                <div className="flex items-center space-x-2">
                <label className="text-gray-700">Quantity</label>
                <input
                    type="number"
                    className="w-16 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                    defaultValue={1}
                />
                </div>
            </div>
    
            <div className="mb-4">
                <label className="block text-gray-700 mb-2">Brand Association:</label>
                <select
                className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                >
                <option>Select a brand</option>
                <option>Brand A</option>
                <option>Brand B</option>
                </select>
            </div>
        </div>
  
        {/* Section: Pricing */}
        <div className="mt-8">
            <h2 className="text-lg font-semibold mb-4">
                Regular price (USD)
            </h2>
            <div className="mb-4">
                <input
                type="text"
                className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                placeholder="Enter regular price"
                />
            </div>
            <div>
                <label className="text-purple-600 cursor-pointer hover:underline">
                Schedule
                </label>
            </div>
        </div>
  
        {/* Section: Upsell */}
        <div className="mt-8">
            <h2 className="text-lg font-semibold mb-4">
                Would you like to add an Upsell to this order?
            </h2>
            <div className="mb-4">
                <select
                className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                >
                <option>Search for a product...</option>
                <option>Upsell A</option>
                <option>Upsell B</option>
                </select>
            </div>
            <div className="grid grid-cols-2 gap-4">
                <div>
                <label className="block text-gray-700 mb-2">Amount (required)</label>
                <input
                    type="text"
                    className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                    placeholder="Enter amount"
                />
                </div>
                <div>
                <label className="block text-gray-700 mb-2">Currency</label>
                <select
                    className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                >
                    <option>USD</option>
                    <option>EUR</option>
                </select>
                </div>
            </div>
            <div className="mt-4">
                <label className="block text-gray-700 mb-2">Purchase rate</label>
                <input
                type="text"
                className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                placeholder="Enter rate"
                />
            </div>
        </div>
  
        {/* Section: Bump */}
        <div className="mt-8">
            <h2 className="text-lg font-semibold mb-4">
            Would you like to add an Bump to this order?
            </h2>
            <div className="mb-4">
            <select
                className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-purple-500">
                <option>Search for a product...</option>
                <option>Upsell A</option>
                <option>Upsell B</option>
            </select>
            </div>
            <div className="grid grid-cols-2 gap-4">
            <div>
                <label className="block text-gray-700 mb-4">Amount (required)</label>
                <input
                type="text"
                className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                placeholder="Enter amount"
                />
            </div>
            <div>
                <label className="block text-gray-700 mb-4">Currency</label>
                <select
                className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                >
                <option>USD</option>
                <option>EUR</option>
                </select>
            </div>
            </div>
            <div className="mt-4">
            <label className="block text-gray-700 mb-2">Purchase rate</label>
            <input
                type="text"
                className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                placeholder="Enter rate"
            />
            </div>
        </div>
        
        {/* Top Section: Dropdowns */}
        <div className="flex justify-between mt-4">
            {/* Left Dropdown */}
            <div className="w-1/2 pr-4">
            <label className="block text-gray-700 text-sm font-semibold mb-2">
                How do you want to get paid?
            </label>
            <select className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-purple-500">
                <option value="">- Select -</option>
                <option value="paypal">PayPal</option>
                <option value="stripe">Stripe</option>
                <option value="bank_transfer">Bank Transfer</option>
            </select>
            </div>

            {/* Right Dropdown */}
            <div className="w-1/2 pl-4">
            <label className="block text-gray-700 text-sm font-semibold mb-2">
                Choose Your Gateway:
            </label>
            <select className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-purple-500">
                <option value="">Select an Option</option>
                <option value="gateway_a">Gateway A</option>
                <option value="gateway_b">Gateway B</option>
                <option value="gateway_c">Gateway C</option>
            </select>
            </div>
        </div>

        {/* Bottom Section: Add Product Button */}
        <div className="flex justify-end mt-2">
            <button className="bg-gray-600 text-white px-6 py-3 rounded hover:bg-gray-700">
            Add Product
            </button>
        </div>
      </div>
    );
  }
  