import {useMemo} from 'react';
import {OrderTotals} from '@common/ito/composables/useOrder';
import {ucFirst} from '@common/utils/string/uc-first';

interface OrderSummaryProps {
  totals: OrderTotals;
}

interface CardStyle {
  icon: string;
  bgColor: string;
  textColor: string;
}

interface SummaryCard extends CardStyle {
  label: string;
  value: number;
}

export function OrderSummary({totals}: OrderSummaryProps) {
  // const [activeTab, setActiveTab] = useState('payments');

  // const tabs = [
  //   { key: 'payments', label: 'Payments' },
  //   { key: 'fees', label: 'Collected fees' },
  //   { key: 'transfers', label: 'Transfers' },
  // ];

  const cardStyle: Record<string, CardStyle> = {
    all: {
      icon: "✅",
      bgColor: "bg-green-100",
      textColor: "text-green-700",
    },
    paid: {
      icon: "✔️",
      bgColor: "bg-green-100",
      textColor: "text-green-700",
    },
    pending: {
      icon: "🔄",
      bgColor: "bg-purple-100",
      textColor: "text-purple-700",
    },
    refunded: {
      icon: "🔄",
      bgColor: "bg-purple-100",
      textColor: "text-purple-700",
    },
    disputed: {
      icon: "⚠️",
      bgColor: "bg-blue-100",
      textColor: "text-blue-700",
    },
    failed: {
      icon: "❌",
      bgColor: "bg-red-100",
      textColor: "text-red-700",
    },
    uncaptured: {
      icon: "📤",
      bgColor: "bg-gray-100",
      textColor: "text-gray-700",
    },
  };

  const cards: SummaryCard[] = useMemo(() => {
    let finalCards: SummaryCard[] = [];

    for(let key in totals) {
      const value = totals[key as keyof OrderTotals];

      if (value !== undefined) {
        finalCards.push({
          ...cardStyle[key.toLowerCase()],
          label: ucFirst(key),
          value,
        });
      }
    }

    return finalCards
  }, [totals])

  console.log('cards: ', cards)

  return (
    <div className="">
      <h1 className="text-xl font-bold text-gray-900">Transactions</h1>
      {/*<div className="mt-4 flex">*/}
      {/*  {tabs.map((tab, index) => (*/}
      {/*    <button*/}
      {/*      key={tab.key}*/}
      {/*      onClick={() => setActiveTab(tab.key)}*/}
      {/*      className={`px-4 py-2 text-sm font-medium first:rounded-l-md last:rounded-r-md ${*/}
      {/*        activeTab === tab.key*/}
      {/*          ? 'bg-purple-800 text-white rounded-md'*/}
      {/*          : 'bg-purple-100 text-gray-700 hover:bg-purple-200'*/}
      {/*      } ${*/}
      {/*        index !== tabs.length - 1 ? 'border-r border-purple-200' : ''*/}
      {/*      }`}*/}
      {/*    >*/}
      {/*      {tab.label}*/}
      {/*    </button>*/}
      {/*  ))}*/}
      {/*</div>*/}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mt-4">
        {cards.map((card, index) => (
          <div
            key={index}
            className="p-4 rounded-lg shadow-sm bg-white border border-gray-100">
            <div className="flex justify-between items-center">
              <p className="text-sm text-gray-500">{card.label}</p>
              <div className={`flex items-center justify-center w-8 h-8 rounded-full ${card.bgColor}`}>
                <span className={`text-lg ${card.textColor}`}>{card.icon}</span>
              </div>
            </div>
            <p className="mt-4 text-2xl font-bold text-gray-900">
              {card.value.toLocaleString()}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
