import {
  ServiceTable
} from '@common/auth/ui/ito/payments/services/listing/service-table';
import ServicePageHeader
  from '@common/auth/ui/ito/payments/services/listing/service-page-header';

export function ITO_ServicePage() {
  return (
    <>
      <ServicePageHeader />
      <ServiceTable />
    </>
  );
}
