import {useMutation} from '@tanstack/react-query';
import {apiClient} from '@common/http/query-client';
import {toast} from '@common/ui/toast/toast';
import {onFormQueryError} from '@common/errors/on-form-query-error';
import {CurrencySymbolPosition} from '@common/ito/composables/useCurrencies';
import {useNavigate} from 'react-router-dom';
import {UseFormReturn} from 'react-hook-form';
import routeNames from '@common/ito/route-names';

export interface ServiceFormValues {
  name: string;
  title: string;
  mode: string;
  public_key: string;
  secret_key: string;
  client_id?: string;
  admin_email?: string;
  status?: number | boolean;
  billing_country: string;
  default_payment_gateway_id: number | string;
  payment_method_ids: number[] | string[];
  brand_id: number | string;
  currency_id: number | string;
  currency_symbol_position: CurrencySymbolPosition | '';
  currency: object;
}

interface CreateServiceProps {
  form: UseFormReturn<any>;
}

async function CreateService(
  payload: ServiceFormValues,
): Promise<Response> {
  const response = await apiClient.post('services', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

export default function useCreateService({form}: CreateServiceProps) {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (payload: ServiceFormValues) => CreateService(payload),
    onSuccess: () => {
      toast('Service created successfully');
      navigate(routeNames.payments.services.listing);
    },
    onError: error => {
      onFormQueryError(error, form);
    },
  });
}
