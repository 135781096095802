import React from 'react';
import { Link } from 'react-router-dom';
import {RightAngleIcon} from '@common/auth/ui/ito/icons/right-angle-icon';
import {HomeIcon} from '@common/auth/ui/ito/icons/home-icon';
import {Trans} from '@common/i18n/trans';
import routeNames from '@common/ito/route-names';

export interface BreadcrumbItem {
  label: string;
  path: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
  className?: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items, className = '' }) => {
  return (
    <div
      className={`text-gray-600 hidden sm:flex flex-col sm:flex-row items-center space-x-5 space-y-3 sm:space-y-0 text-sm ${className}`}>
      <Link
        to={routeNames.payments.settings.listing}
        className="flex items-center cursor-pointer">
        <HomeIcon />
        <span className="ml-1 sm:hidden">
          <Trans message='Home' />
        </span>
      </Link>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <span className='origin-center rotate-90 sm:rotate-0'>
            <RightAngleIcon />
          </span>
          {index === items.length - 1 ? (
            <span className="font-semibold">{item.label}</span>
          ) : (
            <Link to={item.path} className="font-semibold cursor-pointer">
              {item.label}
            </Link>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Breadcrumb;
