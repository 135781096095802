import React from 'react';
import {apiClient} from '@common/http/query-client';

export default function usePaymentMethods() {
  const [paymentMethods, setPaymentMethods] = React.useState<any[]>([]);

  const getPaymentMethods = async () => {
    apiClient.get('payment-methods').then(paymentMethodsResponse => {
      setPaymentMethods(paymentMethodsResponse.data['payment-methods']);
    });
  }

  return {
    paymentMethods,
    getPaymentMethods,
  }
}
