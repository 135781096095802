import React from 'react';

export function UploadIcon() {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.44387 15.0799L11.6734 10.8504M11.6734 10.8504L15.9029 15.0799M11.6734 10.8504V20.3668M20.1324 15.8653C21.424 14.7987 22.2472 13.185 22.2472 11.3791C22.2472 8.16721 19.6434 5.56349 16.4316 5.56349C16.2005 5.56349 15.9844 5.44295 15.8671 5.24389C14.4882 2.90402 11.9424 1.33398 9.02994 1.33398C4.65014 1.33398 1.09961 4.88451 1.09961 9.26431C1.09961 11.449 1.98299 13.4273 3.41204 14.8616"
        stroke="currentColor"
        strokeWidth="2.11475"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>

  );
}
