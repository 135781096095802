import {Trans} from '@common/i18n/trans';
import React from 'react';
import {TableDataProps} from '@common/auth/ui/ito/components/table/TableH';

export default function TableD ({
  text = undefined,
  children = undefined,
  className = '',
  center = false,
  noWrap = false,
  colSpan = 1,
}: TableDataProps) {
  if (text === undefined &&  children === undefined) {
    throw new Error('TableD must have either text or children');
  }

  return (
    <td
      className={
        'px-4 py-5 '
        + className
        + (center && ' text-center')
        + (noWrap && ' whitespace-nowrap')
      }
      colSpan={colSpan}
    >
      {text ? (
        <Trans message={text} />
      ) : (
        <>{children}</>
      )}
    </td>
  );
}
