import {Trans} from '@common/i18n/trans';
import React from 'react';

export function Header() {
  return (
    <header>
      <h1 className="mb-2 text-2xl font-semibold">
        <Trans
          message="Choose The Payment Gateway You Want To Use To Get Paid" />
      </h1>
      <p className="text-gray-500 text-sm  mb-6">
        <Trans message="When you're ready to charge your customers, you'll use a payment
          gateway for the transaction. Card details are always stored securely
          in the PCI DSS compliant ReSkillify vault, so you can change gateways
          at any time without needing to migrate card data." />
      </p>
    </header>
  );
}
