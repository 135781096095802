import Breadcrumb, {BreadcrumbItem} from '@common/auth/ui/ito/breadcrumb';
import {Trans} from '@common/i18n/trans';
import {Link} from 'react-router-dom';

interface OrderPageHeaderProps {
  breadcrumbs: BreadcrumbItem[];
}

export default function OrderPageHeader({breadcrumbs}: OrderPageHeaderProps) {
  return (
    <>
      <Breadcrumb items={breadcrumbs}/>

      <header className="sm:mt-7 mb-6 flex flex-col lg:flex-row items-start lg:items-center space-y-3 lg:space-y-0 justify-between">
        <div>
          <h2 className="text-xl font-semibold text-black">
            <Trans message='Payment Activity' />
          </h2>
          <p className="text-gray-500 text-sm">
            <Trans message='View and update your payment activities' />
          </p>
        </div>
        {/*<div className="flex flex-wrap w-full sm:w-auto space-x-2">*/}
        {/*  <Link to={'#'} className="flex-grow sm:flex-grow-0 text-center bg-purple-800 hover:bg-purple-900 py-1.5 rounded-md px-3 text-sm font-semibold text-white">*/}
        {/*    <Trans message='+ Create Payment' />*/}
        {/*  </Link>*/}
        {/*</div>*/}
      </header>
    </>
  );
}
