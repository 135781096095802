import {useMutation} from '@tanstack/react-query';
import {UseFormReturn} from 'react-hook-form';
import {BackendResponse} from '../../http/backend-response/backend-response';
import {onFormQueryError} from '../../errors/on-form-query-error';
import {useNavigate} from '../../utils/hooks/use-navigate';
import {apiClient} from '../../http/query-client';
import {useAuth} from '../use-auth';
import {useBootstrapData} from '../../core/bootstrap-data/bootstrap-data-context';
import {useCallback} from 'react';
import {setCookie} from 'react-use-cookie';

interface LoginResponse extends BackendResponse {
  bootstrapData: string;
  two_factor: false;
  type?: string;
}
interface TwoFactorResponse {
  two_factor: true;
}

type Response = LoginResponse | TwoFactorResponse;

export interface LoginPayload {
  email?: string;
  password?: string;
  remember?: boolean;
  token_name?: string;
  token?: string;       // for: login through token
}

export function useLogin(form: UseFormReturn<LoginPayload>) {
  const handleSuccess = useHandleLoginSuccess();
  return useMutation({
    mutationFn: login,
    onSuccess: response => {
      if (!response.two_factor) {
        handleSuccess(response);
      }
    },
    onError: r => onFormQueryError(r, form),
  });
}

export function useHandleLoginSuccess() {
  const navigate = useNavigate();
  const {getRedirectUri} = useAuth();
  const {setBootstrapData} = useBootstrapData();
  return useCallback(
    (response: LoginResponse) => {






      // TODO!
      // checking....
      // console.log('response: ', response);
      // console.log('response: ', response.bootstrapData);
      //
      // try {
      //   console.log('response: ', JSON.parse(response.bootstrapData));
      // } catch (e) {
      //   console.warn('e: ', e)
      // }


      setBootstrapData(response.bootstrapData);




      if (response?.type === 'login-with-token') {
      //   // console.log('login-with-token!');
      //   // setBootstrapData(response.bootstrapData);
      //
      //   navigate('/account-settings', {replace: true})
      //   location.replace(getRedirectUri());
      //   return
      }
      //
      // console.log('called....')

      navigate(getRedirectUri(), {replace: false});
    },
    [navigate, setBootstrapData, getRedirectUri],
  );
}

function login(payload: LoginPayload): Promise<Response> {
  if (payload.token) {
    const token = payload.token









    // TODO!
    // checking....
    return apiClient
      .get(`third-party/user-login-with-token?token=${token}`)
      .then(response => {
        const data = response.data;
        data['type'] = 'login-with-token';
        // console.log('initializing setBootstrapData....')

        // const {setBootstrapData} = useBootstrapData();


        // console.log('calling setBootstrapData....')
        // setBootstrapData(data.bootstrapData);
        //
        // console.log('data now: ', data);









        if (localStorage) {
          setTokenAndHeaders(token);
        }

        document.cookie = `token=${payload.token}; path=/; secure; SameSite=Strict`;





        return data;
      });
  }


  console.log('request with password....', payload);




  return apiClient.post('auth/login', payload).then(response => response.data);
}








// TODO!
// checking....
const setTokenAndHeaders = (token: string) => {
  if (localStorage) {
    localStorage.setItem('token', token);
  }

  setCookie('token', token, { path: '/', days: 365 });

  const storedToken = localStorage?.getItem('token');
  if (storedToken) {
    apiClient.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
  }
};
