import React, { useState } from "react";
import { UploadIcon } from "@common/auth/ui/ito/icons/upload-icon";

interface FileUploadDropzoneProps {
  multiple?: boolean;
  allowedFormats: string[];
  maxFileSizeMB: number;
  onFileUpload: (files: File|File[]) => void;
  uploadedImage: string;
  onRemoveUploadedImage: () => void;
}

const FileUploadDropzone: React.FC<FileUploadDropzoneProps> = ({
  allowedFormats = ["XLSX", "XLS", "CSV"],
  maxFileSizeMB = 25,
  multiple = false,
  onFileUpload,
  uploadedImage,
  onRemoveUploadedImage,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [showDefaultImage, setShowDefaultImage] = useState(true);

  const handleFileUpload = (files: File | FileList | null) => {

    if (!files) return;

    const fileArray = Array.isArray(files)
    ? files
    : files instanceof File
    ? [files]
    : Array.from(files);

    const validFiles = fileArray.filter((file) => {
      const fileType = file.name.split(".").pop()?.toUpperCase() || "";
      const fileSizeMB = file.size / 1024 / 1024;

      if (!allowedFormats.includes(fileType)) {
        alert(
          `Invalid file type: ${file.name}. Allowed formats: ${allowedFormats.join(
            ", "
          )}`
        );
        return false;
      }
      if (fileSizeMB > maxFileSizeMB) {
        alert(
          `File ${file.name} exceeds maximum size of ${maxFileSizeMB} MB.`
        );
        return false;
      }
      return true;
    });

    if (validFiles.length > 0) {
      
      if (multiple) {
        setUploadedFiles([...uploadedFiles, ...validFiles]);
        onFileUpload(validFiles); // Call the parent prop with valid files
        return;
      }

      setUploadedFiles([validFiles[0]]);

      onFileUpload(validFiles[0])

    }

    setUploadProgress(0);
    setTimeout(() => {
      setUploadProgress(100);
      setTimeout(() => setUploadProgress(null), 500); // Hide progress bar
    }, 1500);
  };

  const handleRemoveDefaultImage = () => {
    setShowDefaultImage(false);
    onRemoveUploadedImage();
  };

  const handleRemoveFile = (fileName: string) => {
    const filteredFiles = uploadedFiles.filter(
      (file) => file.name !== fileName
    );
    setUploadedFiles(filteredFiles);
    onFileUpload(filteredFiles); // Update parent with new file list
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleFileUpload(e.dataTransfer.files);
  };

  return (
    <div className="p-4 w-full mx-auto">
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        className="border-dashed border-2 border-purple-400 rounded-md p-4 text-center cursor-pointer"
      >
        <input
          type="file"
          className="hidden"
          id="file-upload"
          onChange={(e) => handleFileUpload(e.target.files)}
          multiple
        />
        <label htmlFor="file-upload" className="block">
          <div className="flex text-purple-500 mb-2 w-fit mx-auto rounded-full p-2 shadow">
            <UploadIcon />
          </div>
          <p className="mt-7 text-gray-400 text-xs">
            Drag and drop or file here or
            <span className="text-purple-500 underline">browse</span>
          </p>
        </label>
      </div>
      <p className="text-xs text-gray-400 mt-2">
        Supported formats: {allowedFormats.join(", ")} <br />
        Maximum size: {maxFileSizeMB} MB
      </p>

      <div className="mt-4">
        {uploadedFiles.length === 0 && showDefaultImage && uploadedImage != '' ? (
          <div className="flex items-center justify-between p-2 border rounded-md shadow-sm mb-2">
            <div className="flex items-center space-x-3">
              <img
                src={uploadedImage}
                alt="Uploaded file"
                className="w-8 h-8 rounded-full"
              />
              <div>
                <p className="text-sm font-medium">Uploaded file</p>
              </div>
            </div>
            <button onClick={handleRemoveDefaultImage} className="text-red-500 hover:text-red-700">
              &times;
            </button>
          </div>
        ) : (
          uploadedFiles.map((file, index) => (
            <div
              key={index}
              className="flex items-center justify-between p-2 border rounded-md shadow-sm mb-2"
            >
              <div className="flex items-center space-x-3">
                <img
                  src={URL.createObjectURL(file)} // Create temporary URL for new file
                  alt={file.name || "Uploaded file"}
                  className="w-8 h-8 rounded-full"
                />
                <div>
                  <p className="text-sm font-medium">{file.name}</p>
                  <p className="text-xs text-gray-500">
                    {(file.size / 1024 / 1024).toFixed(2)} MB
                  </p>
                </div>
              </div>
              <button
                onClick={() => handleRemoveFile(file.name)}
                className="text-red-500 hover:text-red-700"
              >
                &times;
              </button>
            </div>
          ))
        )}
      </div>

      {uploadProgress !== null && (
        <div className="w-full bg-gray-200 rounded-full h-2 mt-4">
          <div
            className="bg-purple-500 h-2 rounded-full"
            style={{ width: `${uploadProgress}%` }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default FileUploadDropzone;
