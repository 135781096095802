import {useEffect, useState} from 'react';
import useHelper from '@common/ito/helper';

export default function useLayout() {
  const [stylesLoaded, setStylesLoaded] = useState(false);
  const {unMounted} = useHelper();

  useEffect(() => {
    const link = document.createElement('link');
    link.href = 'https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css';
    link.rel = 'stylesheet';
    link.id = 'tailwindcss-cdn-link';
    link.onload = () => setStylesLoaded(true);
    document.head.appendChild(link);
  }, []);

  unMounted(() => {
    const tailwindcssCdnLink = document.getElementById('tailwindcss-cdn-link');

    if (tailwindcssCdnLink && document.head.contains(tailwindcssCdnLink)) {
      document.head.removeChild(tailwindcssCdnLink);
    }
  });

  return {
    stylesLoaded,
  }
}
