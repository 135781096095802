/**
 * TextAreaField Component
 *
 * A reusable textarea field component with support for labels, tooltips, and error handling,
 * integrated with `react-hook-form` for form state management.
 *
 * Props:
 * --------
 * @param {string} id - The unique identifier for the textarea field. Optional.
 * @param {string} label - The label text displayed above the textarea field. Required.
 * @param {string} [placeholder] - Placeholder text for the textarea field. Optional.
 * @param {number} [rows=3] - The number of rows for the textarea. Defaults to 3.
 * @param {boolean} [required=false] - Indicates whether the textarea field is required. Defaults to false.
 * @param {string} [labelTooltipMessage] - A tooltip message displayed next to the label. Optional.
 * @param {TooltipPosition} [labelTooltipPosition=TooltipPosition.Top] - The position of the label's tooltip. Defaults to 'Top'.
 * @param {string} [labelTooltipColor='bg-gray-600'] - The background color of the label's tooltip. Defaults to 'bg-gray-600'.
 * @param {UseFormReturn<any>} form - The `react-hook-form` instance used to manage form state. Required.
 * @param {string} name - The name of the textarea field, used for form registration and error handling. Required.
 *
 * Example Usage:
 * --------------
 * <TextAreaField
 *   id="description"
 *   label="Description"
 *   placeholder="Enter a description"
 *   rows={5}
 *   required={true}
 *   labelTooltipMessage="Provide a detailed description"
 *   form={form}
 *   name="description"
 * />
 */

import React, {ReactNode} from 'react';
import { UseFormReturn } from 'react-hook-form';
import { TooltipPosition } from '@common/auth/ui/ito/components/tooltip';
import FieldLabel from '@common/auth/ui/ito/components/field-label';

export interface TextAreaFieldProps {
  id?: string;
  label?: string | ReactNode;
  placeholder?: string;
  rows?: number;
  required?: boolean;
  labelTooltipMessage?: string;
  labelTooltipPosition?: TooltipPosition;
  labelTooltipColor?: string;
  form: UseFormReturn<any>;
  name: string;
}

const TextAreaField: React.FC<TextAreaFieldProps> = ({
  id,
  label = '',
  placeholder,
  rows = 3,
  required = false,
  labelTooltipMessage = undefined,
  labelTooltipPosition = TooltipPosition.Top,
  labelTooltipColor = 'bg-gray-600',
  form,
  name,
}) => {
  const error = form.formState.errors[name];
  const errorMessage = error && 'message' in error ? error.message : '';

  return (
    <div className="mb-4">
      {label && (
        <FieldLabel
          label={label}
          for={id}
          starBefore={required}
          tooltipMessage={labelTooltipMessage}
          tooltipPosition={labelTooltipPosition}
          tooltipColor={labelTooltipColor}
        />
      )}
      <textarea
        {...form.register(name, required ? {required: `${label} is required`} : {})}
        id={id}
        placeholder={placeholder}
        rows={rows}
        className="border-gray-300 focus:ring-purple-500 focus:border-purple-500 px-2.5 py-1.5 w-full rounded-md border shadow-sm"
      />
      {errorMessage && (
        <div className="text-red-500 text-xs">{errorMessage as string}</div>
      )}
    </div>
  );
};

export default TextAreaField;
