import {RegisterPage} from './ui/register-page';
import {AuthRoute} from './guards/auth-route';
import {GuestRoute} from './guards/guest-route';
import {ForgotPasswordPage} from './ui/forgot-password-page';
import {ResetPasswordPage} from './ui/reset-password-page';
import React, {Fragment} from 'react';
import {Route, Outlet} from 'react-router-dom';
import {LoginPageWrapper} from '@common/auth/ui/login-page-wrapper';
import {
  ITO_AccountSettingsPage
} from '@common/auth/ui/ito/account-settings/account-settings-page';
import {
  ITO_PaymentDashboardPage
} from '@common/auth/ui/ito/payments/dashboard/page';
import {
  ITO_PaymentGatewayPage
} from '@common/auth/ui/ito/payments/gateway/listing';
import {  ITO_PaymentProductAndServicesSell } from '@common/auth/ui/ito/payments/services/sell';
import {
  ITO_PaymentMethodsPage
} from '@common/auth/ui/ito/payments/methods/page';
import {
  ITO_PaymentProfilePage
} from '@common/auth/ui/ito/payments/profile/page';
import {ITO_Layout} from '@common/ui/layout/ito/layout-component';
import {
  ITO_ChoosePaymentGatewayPage
} from '@common/auth/ui/ito/payments/gateway/choose-payment-gateway';
import {
  ITO_PaymentSettingsPage
} from '@common/auth/ui/ito/payments/settings/page';
import {
  ITO_CreatePaymentGatewayPage
} from '@common/auth/ui/ito/payments/gateway/create/create-payment-gateway-page';
import {
  ITO_UpdatePaymentGatewayPage
} from '@common/auth/ui/ito/payments/gateway/edit/update-payment-gateway-page';
import {
  ITO_CreateServicePage
} from '@common/auth/ui/ito/payments/services/create/create-service-page';
import {
  ITO_UpdateServicePage
} from '@common/auth/ui/ito/payments/services/edit/update-service-page';
import {ITO_ServicePage} from '@common/auth/ui/ito/payments/services/listing';
import {
  ITO_OrderPage
} from '@common/auth/ui/ito/payments/orders/list';

export const AuthRoutes = (
  <Fragment>
    {/* Auth Routes */}
    <Route
      path='/'
      element={
      <AuthRoute>
        <Outlet />
      </AuthRoute>
    }>
      {/*<Route path="/account-settings" element={<AccountSettingsPage />} />*/}
      <Route
        path='/'
        element={
          <ITO_Layout>
            <Outlet />
          </ITO_Layout>
        }
      >
        <Route path="account-settings" element={<ITO_AccountSettingsPage />} />
        <Route path='payments' element={<Outlet />}>
          <Route path='dashboard' element={<ITO_PaymentDashboardPage />} />
          <Route path='gateway' element={<Outlet />} >
            <Route path='' element={<ITO_PaymentGatewayPage />} />
            <Route path='choose' element={<ITO_ChoosePaymentGatewayPage />} />
            <Route path=':gateway/create' element={<ITO_CreatePaymentGatewayPage />} />
            <Route path=':gatewayId/edit' element={<ITO_UpdatePaymentGatewayPage />} />
          </Route>
          <Route path='profile' element={<ITO_PaymentProfilePage />} />
          <Route path='services' element={<Outlet />} >
            <Route path='' element={<ITO_ServicePage />} />
            <Route path='create' element={<ITO_CreateServicePage />} />
            <Route path=':serviceId/edit' element={<ITO_UpdateServicePage />} />
            <Route path='sell' element={<ITO_PaymentProductAndServicesSell />} />
          </Route>
          <Route path='methods' element={<ITO_PaymentMethodsPage />} />
          <Route path='settings' element={<ITO_PaymentSettingsPage />} />
          <Route path='orders' element={<ITO_OrderPage />} />
        </Route>
      </Route>
    </Route>

    {/* Guest Routes */}
    <Route
      path='/'
      element={
      <GuestRoute>
        <Outlet />
      </GuestRoute>
    }>
      <Route path="register" element={<RegisterPage />} />
      <Route path="login" element={<LoginPageWrapper />} />
      <Route path="workspace/join/register" element={<RegisterPage />} />
      <Route path="workspace/join/login" element={<LoginPageWrapper />} />
      <Route path="forgot-password" element={<ForgotPasswordPage />} />
      <Route path="password/reset/:token" element={<ResetPasswordPage />} />
    </Route>
  </Fragment>
);
