import React, {ReactNode} from 'react';

interface TableBodyProps {
  className?: string;
  children: ReactNode;
}

export default function TableBody ({
  children,
  className = '',
}: TableBodyProps) {
  return (
    <tbody className={`text-sm text-black ${className}`}>
      {children}
    </tbody>
  );
}
