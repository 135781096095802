import React from 'react';
import {apiClient} from '@common/http/query-client';

export enum CurrencySymbolPosition {
  left = 'left',
  right = 'right',
}

export interface Currency {
  id: number;
  name: string;
  symbol: string;
  code: string;
  symbol_position: CurrencySymbolPosition;
  created_at?: string;
  updated_at?: string;
}

export default function useCurrencies() {
  const [currencies, setCurrencies] = React.useState<Currency[]>([]);

  const getCurrencies = async () => {
    apiClient.get('currencies').then(currencyResponse => {
      setCurrencies(currencyResponse.data.currencies);
    });

  }

  return {
    currencies,
    getCurrencies,
  }
}
