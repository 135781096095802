import {useContext, useEffect, useRef, useState} from 'react';
import { User } from '@common/auth/user';
import { useLogout } from '@common/auth/requests/logout';
import { useUser } from '@common/auth/ui/use-user';
import {SiteConfigContext} from '@common/core/settings/site-config-context';
import {useAuth} from '@common/auth/use-auth';
import {useSettings} from '@common/core/settings/use-settings';
import {useThemeSelector} from '@common/ui/themes/theme-selector-context';

export default function useITONavbar () {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const fullName = `${user?.first_name ?? ''} ${user?.last_name ?? ''}`;
  const logout = useLogout();
  const hasUnreadNotif = !!user?.unread_notifications_count;
  const {auth} = useContext(SiteConfigContext);
  const {isSubscribed} = useAuth();
  const {themes} = useSettings();
  const { data, isLoading } = useUser('me', {
    with: ['roles', 'social_profiles', 'tokens'],
  });
  const profileDropdownRef = useRef<HTMLDivElement | null>(null);
  const notificationDropdownRef = useRef<HTMLDivElement | null>(null);

  const {selectedTheme, selectTheme} = useThemeSelector();

  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState);
  };

  const toggleNotificationDropdown = () => {
    setIsNotificationDropdownOpen(prevState => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        profileDropdownRef.current &&
        !profileDropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }

      if (
        notificationDropdownRef.current &&
        !notificationDropdownRef.current.contains(event.target as Node)
      ) {
        setIsNotificationDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!isLoading && data?.user) {
      setUser(data.user);
    }
  }, [isLoading, data]);

  return {
    themes,
    auth,
    user,
    fullName,
    isSubscribed,
    isDropdownOpen,
    isNotificationDropdownOpen,
    profileDropdownRef,
    notificationDropdownRef,
    hasUnreadNotif,
    logout,
    selectedTheme,
    selectTheme,
    toggleDropdown,
    toggleNotificationDropdown,
    setIsNotificationDropdownOpen,
  };
}
